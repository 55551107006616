import useApi from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import { selectCurrentPortfolioId } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { useSelector } from 'react-redux'

const useUpdateSustainabilityPreference = () => {
  const portfolioId = useSelector(selectCurrentPortfolioId)

  const updateSustainabilityPreferences = useApi(
    `/portfolios/${portfolioId}/sustainability_preferences`,
    {
      method: 'PATCH',
      withCredentials: true,
      errorHandling: {
        ignore: {
          client: true
        }
      }
    }
  )

  return updateSustainabilityPreferences
}

export default useUpdateSustainabilityPreference
