import { selectCurrentUser } from '@nordinvestments/nord-storybook/dist/store/current/user'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const useStateMessageData = () => {
  const { accountType, email, firstName } = useSelector(selectCurrentUser)
  const isCompany = accountType === 'company_account'

  const accountCreationPeriodText = isCompany
    ? 'Det tager typisk 4-5 hverdage.'
    : 'Det tager typisk ikke mere end en dag.'

  const data = useMemo(
    () => ({
      userCreated: {
        title: `Hej ${firstName}`,
        message: (
          <>
            Du har endnu ikke færdiggjort din oprettelse. Gå til{' '}
            <Link to="/konto/oprettelser">depotoprettelser</Link> for at
            forsætte.
          </>
        )
      },
      documentsSigned: {
        title: 'Tak for din oprettelse',
        message: `Vi behandler din oprettelse hurtigst muligt, og giver dig besked på ${email} når din konto er klar til at modtage overførsler. ${accountCreationPeriodText} Du skal ikke foretage dig mere nu.`
      }
    }),
    [accountCreationPeriodText, email, firstName]
  )

  return data
}

export default useStateMessageData
