import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import useApi, {
  useApiRequest
} from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import React, { useCallback } from 'react'

import CenteredTextSection from '../../../../components/CenteredTextSection'

import ReportGenerationForm from './ReportGenerationForm'
import ReportList from './ReportList'

const { key: saxoReportsFolderName } = getConfig(
  'documentationFolders.saxoReports'
)

const SaxoReports = () => {
  const { id: portfolioId, invested } = useObjectSelector(
    selectCurrentPortfolio
  )

  const fetchDocuments = useApi(
    `/onboarding/documents?folder=${saxoReportsFolderName}&portfolio_id=${portfolioId}`,
    {
      withCredentials: true
    }
  )

  const {
    data: documents,
    loading: loadingDocuments,
    request: refreshDocuments
  } = useApiRequest(fetchDocuments, { autoCall: true })

  const handleReportGenerated = useCallback(async () => refreshDocuments(), [
    refreshDocuments
  ])

  return (
    <>
      <Text as="h4" align="center" className="my-3">
        Generer rapporter
      </Text>
      {invested ? (
        <>
          <ReportGenerationForm
            portfolioId={portfolioId}
            onGenerated={handleReportGenerated}
          />
          <hr />
          <Text as="h4" align="center" className="my-5">
            Dine rapporter
          </Text>
          <ReportList documents={documents} loading={loadingDocuments} />
        </>
      ) : (
        <CenteredTextSection>
          Du kan først oprette og downloade rapporter efter at du er blevet
          investeret.
        </CenteredTextSection>
      )}
    </>
  )
}

export default SaxoReports
