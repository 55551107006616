import NavMenu from '@nordinvestments/nord-storybook/dist/components/NavMenu'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import React, { useState } from 'react'

import DateSelection from '../../pages/AuthPages/ChartPage/DateSelection'

import useNavigationItems from './useNavigationItems'

const Header = () => {
  const { appState, createdInSaxoAt } = useObjectSelector(
    selectCurrentPortfolio
  )
  const [showMobileNav, setShowMobileNav] = useState(false)

  const handleToggleMobileNav = (newShow) => setShowMobileNav(newShow)
  const isInvested = appState === 'invested' || appState === 'deposit_recieved'
  const isCreatedInSaxo = !!createdInSaxoAt

  const {
    left: leftNav,
    right: rightNav,
    footer: footerNav
  } = useNavigationItems({
    isInvested,
    isCreatedInSaxo,
    onToggleMobileNav: handleToggleMobileNav
  })

  return (
    <NavMenu
      showMobileNav={showMobileNav}
      onToggleMobileNav={handleToggleMobileNav}
      leftNav={leftNav}
      rightNav={rightNav}
      footer={
        <>
          {isInvested && (
            <>
              <div className="d-flex flex-row justify-content-center d-lg-none mb-1">
                {footerNav}
              </div>
              <DateSelection />
            </>
          )}
        </>
      }
      fluid
    />
  )
}

export default Header
