import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import {
  changeCurrentPortfolio,
  selectCurrentPortfolio
} from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import AppContext from '../../../../context/AppContext'
import useIsPayoutAccount from '../../../../hooks/useIsPayoutAccount'

const useUpdatePortfolioTotalValue = (liveData, loadingLiveData) => {
  const dispatch = useDispatch()
  const { dateTo, chartType } = useContext(AppContext)

  const { appState: portfolioAppState, totalValue } = useObjectSelector(
    selectCurrentPortfolio
  )

  const isPayoutAccount = useIsPayoutAccount()
  const isInvested = portfolioAppState === 'invested'

  useEffect(() => {
    if (!isInvested) return
    if (isPayoutAccount) return
    if (chartType !== 'values') return
    if (loadingLiveData) return
    if (!liveData) return
    if (dateTo) return

    const { value } = liveData
    if (value === undefined) return
    if (value === totalValue) return

    dispatch(
      changeCurrentPortfolio({
        totalValue: value
      })
    )
  }, [
    dateTo,
    dispatch,
    liveData,
    loadingLiveData,
    totalValue,
    chartType,
    isPayoutAccount,
    isInvested
  ])
}

export default useUpdatePortfolioTotalValue
