import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import { selectCurrentPortfolioId } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { selectCurrentUser } from '@nordinvestments/nord-storybook/dist/store/current/user'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import Header from '../../components/Header'

import ChartPage from './ChartPage'
import PortfolioPage from './PortfolioPage'
import PortfolioSelectionPage from './PortfolioSelectionPage'
import ProfilePage from './ProfilePage'
import UserStatePage from './UserStatePage'

const defaultChartTypePath = 'historisk'

const AuthPages = () => {
  const { appState } = useSelector(selectCurrentUser)
  const currentPortfolioId = useSelector(selectCurrentPortfolioId)

  const isCreated = appState === 'user_created'
  const isSigned = appState === 'documents_signed'
  const isOnboarded =
    currentPortfolioId &&
    (appState === 'saxo_onboarded' || appState === 'invested')

  let defaultRedirect
  if (isOnboarded)
    defaultRedirect = `/portefoeljer/${currentPortfolioId}/${defaultChartTypePath}`
  else if (isSigned)
    defaultRedirect = `/portefoeljer/${currentPortfolioId}/information`
  else if (isCreated) defaultRedirect = '/'

  let MainPage
  if (isOnboarded) MainPage = ChartPage
  else if (isSigned || isCreated) MainPage = UserStatePage
  else MainPage = Loader

  return (
    <Container
      className="position-fixed w-100 h-100 d-flex flex-column overflow-hidden"
      fluid
    >
      <Row>
        <Col className="p-0">
          <Header />
        </Col>
      </Row>
      <Row className="flex-fill vw-100">
        <Col className="p-0">
          <main className="h-100 w-100 pt-5">
            <MainPage />
            <Switch>
              {isOnboarded && <Route path="/portefoeljer/:id/historisk" />}
              {isOnboarded && <Route path="/portefoeljer/:id/tidsvaegtet" />}
              {isOnboarded && <Route path="/portefoeljer/:id/afkast" />}
              {isSigned && <Route path="/portefoeljer/:id/information" />}
              <Route path="/konto" component={ProfilePage} />
              <Route path="/portefoeljer/:id" component={PortfolioPage} />
              <Route
                path="/portefoeljer"
                component={PortfolioSelectionPage}
                exact
              />
              {defaultRedirect && <Redirect to={defaultRedirect} />}
            </Switch>
          </main>
        </Col>
      </Row>
    </Container>
  )
}

export default AuthPages
