import styleConfiguration from '@nordinvestments/nord-storybook/dist/configuration/styleConfiguration'
import groupBy from 'lodash/groupBy'
import sumBy from 'lodash/sumBy'

const { blues } = styleConfiguration

// eslint-disable-next-line import/prefer-default-export
export const formatDataForChart = (data) => {
  if (!data) return undefined

  const chartData = Object.entries(groupBy(data, 'category')).map(
    (assetsTypes, index) => ({
      name: assetsTypes[0],
      value: sumBy(assetsTypes[1], 'percentage'),
      color: blues[(index + 1) * 100]
    })
  )

  return chartData
}
