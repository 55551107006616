import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import React from 'react'

const AccountValue = () => {
  const { totalValue } = useObjectSelector(selectCurrentPortfolio)

  return (
    <div className="pt-2">
      <Text as="h6" align="center">
        Værdi
      </Text>
      <Text as="h1" align="center" variant="white" className="mb-0">
        {numberToCurrency(totalValue)}
      </Text>
    </div>
  )
}

export default AccountValue
