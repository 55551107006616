import React from 'react'

import MessageCard from '../../../components/MessageCard'

import usePortfolioAppState from './usePortfolioAppState'
import useStateMessageData from './useStateMessageData'

const PortfolioStatePage = () => {
  const appState = usePortfolioAppState()

  const {
    [appState]: { title, message, children }
  } = useStateMessageData()

  return (
    <div className="my-auto">
      <MessageCard title={title} message={message}>
        {children}
      </MessageCard>
    </div>
  )
}

export default PortfolioStatePage
