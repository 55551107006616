import Circle from '@nordinvestments/nord-storybook/dist/components/Circle'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import User from 'react-feather/dist/icons/user'
import UserCheck from 'react-feather/dist/icons/user-check'
import UserPlus from 'react-feather/dist/icons/user-plus'

const referralVariants = {
  created: {
    icon: User,
    variant: 'secondary'
  },
  invested: {
    icon: UserCheck,
    variant: 'info'
  },
  paid: {
    icon: UserPlus,
    variant: 'dark-green'
  }
}

const referralAliases = {
  eligible: 'invested'
}

const ReferralRow = ({
  amount,
  humanizedLastUpdatedAt,
  title,
  message,
  status
}) => {
  const { variant, icon } =
    referralVariants[status] || referralVariants[referralAliases[status]]

  return (
    <Row as="li" className="border-bottom py-2">
      <Col xs={9}>
        <div className="d-flex align-items-center">
          <div>
            <Circle className="shadow-none" size="sm" variant={variant}>
              <Icon icon={icon} size="16" />
            </Circle>
          </div>
          <div>
            <Text as="div" align="left" className="pl-3">
              {title}
            </Text>
            <Text as="div" align="left" variant="secondary" className="pl-3">
              {message}
            </Text>
          </div>
        </div>
      </Col>
      <Col xs={3}>
        <Text as="div" align="right" className="mb-0">
          {numberToCurrency(amount)}
        </Text>
        <Text as="div" align="right" variant="secondary">
          Opdateret: {humanizedLastUpdatedAt}
        </Text>
      </Col>
    </Row>
  )
}

ReferralRow.propTypes = {
  amount: PropTypes.number.isRequired,
  humanizedLastUpdatedAt: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default ReferralRow
