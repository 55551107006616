import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import React from 'react'
import Col from 'react-bootstrap/Col'

import { useAllocationContext } from './AllocationContext'
import Chart from './Chart'

const ChartWrapper = () => {
  const { chartData } = useAllocationContext()

  return (
    <Col xs={12} lg={{ span: 5, offset: 1 }} className="my-lg-auto">
      {chartData.length === 1 ? (
        <Text as="p">
          Der er ingen kontanter eller investeringer på kontoen.
        </Text>
      ) : (
        <Chart />
      )}
    </Col>
  )
}

export default ChartWrapper
