import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useQuery from '@nordinvestments/nord-storybook/dist/hooks/useQuery'
import React, { useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'

import { useSustainabilityContext } from '../Provider'

import Content from './Content'

const IntroPage = () => {
  const { cleanupContext } = useSustainabilityContext()
  const successQueryParam = useQuery('success')
  const errorOccured = successQueryParam === 'false'

  useEffect(() => {
    cleanupContext()
  }, [cleanupContext])

  return (
    <>
      <Text as="h4" align="center" className="my-3">
        Bæredygtighedspreferencer
      </Text>
      {errorOccured && (
        <Alert variant="danger" className="text-center">
          <h6>Vi beklager, der er sket en fejl... </h6>
          Vi har rapporteret fejlen, og vil forsøge at udbedre den hurtigst
          muligt. Du kan genindlæse siden for at prøve igen. Oplever du forsat
          fejl så kontakt os, vi sidder klar til at hjælpe!
        </Alert>
      )}
      <Content />
    </>
  )
}

export default IntroPage
