import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useApi, {
  useApiRequest
} from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import React, { useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import usePortfolioId from '../../../../hooks/usePortfolioId'

import { AllocationContext } from './AllocationContext'
import ChartWrapper from './ChartWrapper'
import List from './List'
import { formatDataForChart } from './utilities'

const Allocation = () => {
  const portfolioId = usePortfolioId()

  const callAllocationData = useApi(`/portfolios/${portfolioId}/allocations`, {
    method: 'GET',
    withCredentials: true
  })

  const { data: allocationData } = useApiRequest(callAllocationData, {
    autoCall: true,
    payload: {
      includeCash: true
    }
  })

  const chartData = formatDataForChart(allocationData)

  const context = useMemo(
    () => ({
      allocationData,
      chartData
    }),
    [allocationData, chartData]
  )

  return (
    <AllocationContext.Provider value={context}>
      <Row>
        <Col xs={12}>
          <Text as="h4" align="center" className="my-3">
            Porteføljesammensætning
          </Text>
        </Col>
        {chartData && allocationData ? (
          <>
            <List />
            <ChartWrapper />
          </>
        ) : (
          <Col>
            <Loader className="text-center font-size-xxl" />
          </Col>
        )}
      </Row>
    </AllocationContext.Provider>
  )
}

export default Allocation
