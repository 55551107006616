import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import formatDate from '@nordinvestments/nord-storybook/dist/utilities/formatDate'
import React from 'react'
import { Link } from 'react-router-dom'

import { preferenceLevelAliases, preferenceLevels } from './data'

const sustainabilityPreferenceStartDate = new Date(2022, 6, 25)

const Content = () => {
  const {
    id: portfolioId,
    sustainabilityPreferenceLevel,
    sustainabilityPreferenceGoalAnswer
  } = useObjectSelector(selectCurrentPortfolio)

  const withSustainabilityPreference = !!sustainabilityPreferenceLevel

  const text =
    preferenceLevels[sustainabilityPreferenceLevel] ||
    preferenceLevels[preferenceLevelAliases[sustainabilityPreferenceLevel]]

  const formattedText =
    typeof text === 'function'
      ? text({ answer: sustainabilityPreferenceGoalAnswer })
      : text

  if (withSustainabilityPreference)
    return (
      <>
        <Text as="p" align="center">
          Her kan du se dine præferencer i forhold til bæredygtig investering
          for porteføljen.
        </Text>
        <Text as="p" align="center">
          {formattedText}
        </Text>
        <Text as="p" align="center">
          Ønsker du at ændre dine præferencer i forhold til bæredygtig
          investering?
        </Text>
        <div className="d-flex justify-content-center">
          <BaffleButton
            as={Link}
            to={`/portefoeljer/${portfolioId}/baeredygtighedspreferencer/sporgsmal`}
            filled
          >
            Start her
          </BaffleButton>
        </div>
      </>
    )

  return (
    <>
      <Text as="p" align="center">
        Sidst du fik en investeringsanbefaling fra NORD var før den{' '}
        {formatDate(sustainabilityPreferenceStartDate, 'PPP')}, og inden vi
        kortlagde vores kunders præferencer i forhold til bæredygtig
        investering.
      </Text>
      <Text as="p" align="center">
        Ønsker du at registrere dine præferencer i forhold til bæredygtig
        investering og modtage en ny investeringsanbefaling?
      </Text>
      <div className="d-flex justify-content-center">
        <BaffleButton
          as={Link}
          to={`/portefoeljer/${portfolioId}/baeredygtighedspreferencer/sporgsmal`}
          filled
        >
          Start her
        </BaffleButton>
      </div>
    </>
  )
}

export default Content
