import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import PropTypes from 'prop-types'
import React from 'react'

import CenteredTextSection from '../../../../components/CenteredTextSection'

import ResultPage from './ResultPage'
import StartPage from './StartPage'

const CashDeposit = ({ children }) => (
  <CenteredTextSection>
    <Text as="h4" align="center" className="mt-3">
      Nem overførsel til din konto i Saxo Bank
    </Text>
    {children}
  </CenteredTextSection>
)

CashDeposit.propTypes = {
  children: PropTypes.node.isRequired
}

CashDeposit.ResultPage = ResultPage
CashDeposit.StartPage = StartPage

export default CashDeposit
