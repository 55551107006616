import { object, number, string } from 'yup'

const validationSchema = (availableAmountAfterFees) =>
  object().shape({
    portfolioId: number().required(),
    externalAccountId: number().required(),
    amount: number().max(availableAmountAfterFees).required(),
    transactionMessage: string().matches(
      /^[\x20-\x7F]*$/, // RegEx for standard ascii hex range
      'Beskeden må kun indeholde almindelige tal, tegn og bogstaver (eksl. æøå).'
    )
  })

export default validationSchema
