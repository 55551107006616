import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import {
  setCurrentPortfolioId,
  selectCurrentPortfolioId
} from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { selectPortfolios } from '@nordinvestments/nord-storybook/dist/store/current/portfolios'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import isEmpty from 'lodash/isEmpty'
import round from 'lodash/round'
import sum from 'lodash/sum'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import ModalPage from '../../../components/ModalPage'
import { CHART_TYPE_PATHS } from '../../../configuration/constants'
import AppContext from '../../../context/AppContext'

const getTotalValueAndReturn = (portfoliosData) => {
  if (isEmpty(portfoliosData)) return null

  const portfolioTotalValues = portfoliosData.map(
    ({ totalValue }) => totalValue
  )

  const portfolioReturns = portfoliosData.map(({ returnInDkk }) => returnInDkk)

  return {
    totalValue: round(sum(portfolioTotalValues)),
    totalReturn: round(sum(portfolioReturns))
  }
}

const returnVariants = {
  positive: { variant: 'dark-green', symbol: '+' },
  negative: { variant: 'danger', symbol: '' }
}

const ReturnText = ({ value }) => {
  if (value === 0)
    return (
      <Text as="div" align="center">
        {numberToCurrency(value)}
      </Text>
    )

  const returnType = value > 0 ? 'positive' : 'negative'

  const { symbol, variant } = returnVariants[returnType]

  return (
    <Text as="div" align="center" variant={variant}>
      {symbol} {numberToCurrency(value)}
    </Text>
  )
}

ReturnText.propTypes = {
  value: PropTypes.number.isRequired
}

const PortfolioSelectionPage = () => {
  const dispatch = useDispatch()
  const portfoliosData = useSelector(selectPortfolios)
  const currentPortfolioId = useSelector(selectCurrentPortfolioId)

  const handleChangeCurrentPortfolioId = (id) =>
    dispatch(setCurrentPortfolioId(id))

  const { chartType } = useContext(AppContext)

  const chartParam = Object.keys(CHART_TYPE_PATHS).find(
    (key) => CHART_TYPE_PATHS[key] === chartType
  )
  const backUrl = `/portefoeljer/${currentPortfolioId}/${chartParam}`

  const { totalValue, totalReturn } = getTotalValueAndReturn(portfoliosData)

  return (
    <ModalPage
      title="Vælg porteføjle"
      backUrl={backUrl}
      header={
        <Text as="h3" className="ml-auto">
          Porteføljer
        </Text>
      }
      closeButton
    >
      {isEmpty(portfoliosData) ? (
        <Loader className="text-center my-5 font-size-xl" />
      ) : (
        <>
          <div className="mx-lg-6 d-flex justify-content-center align-items-center">
            <Text as="h4" size="lg" className="mb-0">
              Totalværdi
            </Text>
            <Text as="div" variant="secondary" className="ml-1">
              {numberToCurrency(totalValue)}
            </Text>
          </div>
          <div className="mx-lg-6 d-flex justify-content-center align-items-center">
            <Text as="h6" className="mb-0 mr-1">
              Total afkast
            </Text>
            <ReturnText value={totalReturn} />
          </div>
          <Row className="my-4 justify-content-center">
            {portfoliosData.map(
              ({ id, title, totalValue: value, returnInDkk }) => (
                <Col xs={12} md={6} lg={4} className="my-2">
                  <Card key={id} className="shadow h-100">
                    <Card.Body
                      onClick={() => handleChangeCurrentPortfolioId(id)}
                      className="d-flex flex-column align-items-center"
                    >
                      <Text as="h6" align="center">
                        {title}
                      </Text>
                      <div className="mt-auto">
                        <Text
                          as="div"
                          align="center"
                          variant="secondary"
                          className="mb-auto"
                        >
                          {numberToCurrency(value)}
                        </Text>
                        <ReturnText value={returnInDkk} />
                        <BaffleButton
                          as={Link}
                          to={`/portefoeljer/${id}/historisk`}
                          size="sm"
                          className="stretched-link mt-4"
                          data-cy={`portfolio-${id}-link`}
                        >
                          Vælg porteføjle
                        </BaffleButton>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )
            )}
          </Row>
        </>
      )}
    </ModalPage>
  )
}

export default PortfolioSelectionPage
