import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import DateRangeInput from '@nordinvestments/nord-storybook/dist/components/DateRangeInput'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { formatISO, isToday, parseISO } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import Row from 'react-bootstrap/Row'

import AppContext from '../../../../context/AppContext'

const formatDate = (date) =>
  formatISO(date, {
    representation: 'date'
  })

const CustomDateRangePopover = ({ target, show, onClose }) => {
  const { onChangeDate, dateFrom } = useContext(AppContext)

  const { investedAt } = useObjectSelector(selectCurrentPortfolio)

  const investedDate = formatDate(parseISO(investedAt))
  const todayDate = formatDate(new Date())
  const [dateRangeValues, setDateRangeValues] = useState({})

  const dateRangeValuesWithDefaults = {
    from:
      dateRangeValues.from !== undefined
        ? dateRangeValues.from
        : dateFrom || investedDate,
    to: dateRangeValues.to !== undefined ? dateRangeValues.to : todayDate
  }

  const handleDateRangeValuesChange = (value) =>
    setDateRangeValues({ ...dateRangeValues, ...value })

  const handleSubmit = (event) => {
    event.preventDefault()
    const { from, to } = dateRangeValuesWithDefaults
    const isDateToToday = isToday(parseISO(to))

    if (isDateToToday) {
      onChangeDate(from)
      onClose()
    } else {
      onChangeDate(from, to)
      onClose()
    }
  }

  return (
    <Overlay
      show={show}
      target={target}
      placement="bottom"
      onHide={onClose}
      rootClose
    >
      <Popover>
        <Popover.Content className="p-4">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <DateRangeInput
                  value={dateRangeValuesWithDefaults}
                  min={investedDate}
                  max={todayDate}
                  onValueChange={handleDateRangeValuesChange}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-4">
              <BaffleButton
                type="submit"
                disabled={
                  dateRangeValuesWithDefaults.from ===
                  dateRangeValuesWithDefaults.to
                }
              >
                Vælg
              </BaffleButton>
            </div>
          </Form>
        </Popover.Content>
      </Popover>
    </Overlay>
  )
}

CustomDateRangePopover.propTypes = {
  onClose: PropTypes.func.isRequired,
  target: PropTypes.shape({}).isRequired,
  show: PropTypes.bool.isRequired
}

export default CustomDateRangePopover
