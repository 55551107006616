import CurrencyInput from '@nordinvestments/nord-storybook/dist/components/CurrencyInput'
import Form from '@nordinvestments/nord-storybook/dist/components/Form'
import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import Select from '@nordinvestments/nord-storybook/dist/components/Select'
import useApi, {
  useApiRequest
} from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import useQuery from '@nordinvestments/nord-storybook/dist/hooks/useQuery'
import {
  selectPortfolios,
  selectPortfolioById
} from '@nordinvestments/nord-storybook/dist/store/current/portfolios'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { useSelector } from 'react-redux'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import usePageLeaveWarning from '../../../../hooks/usePageLeaveWarning'

import ConfirmationPage from './ConfirmationPage'
import ErrorPage from './ErrorPage'
import labelDescriptions from './labelDecriptions'
import validationSchema from './validationSchema'

const formatAccountData = (options, id) => {
  const formattedData = options.filter(({ value }) => value === id)

  return formattedData[0].label
}

const selectProps = {
  isSearchable: false,
  placeholder: 'Vælg konto...'
}

const WithdrawalPage = () => {
  const portfoliosData = useSelector(selectPortfolios)
  const verificationSuccess = useQuery('success')

  usePageLeaveWarning()

  const filteredPortfoliosData = portfoliosData.filter(
    ({ createdInSaxoAt }) => !!createdInSaxoAt
  )
  const hasOnlyOnePortfolio = filteredPortfoliosData.length === 1
  const portfoliosOptions = filteredPortfoliosData.map(
    ({ id, title, registrationNumber, saxoAccountNumber, totalValue }) => {
      const totalValueFormatted = numberToCurrency(totalValue)

      return {
        value: id,
        label: `${title}: ${registrationNumber} - ${saxoAccountNumber} (${totalValueFormatted})`
      }
    }
  )

  const defaultPortfolioId = hasOnlyOnePortfolio
    ? portfoliosOptions[0].value
    : null

  const [portfolioId, setPortfolioId] = useState(defaultPortfolioId)
  const [isWithdrawalCompleted, setWithdrawalIsCompleted] = useState(false)
  const [confirmationPageData, setConfirmationPageData] = useState({})

  const { pensionDepot: isPensionPortfolio } = useObjectSelector(
    selectPortfolioById(portfolioId)
  )

  useEffect(() => {
    if (!defaultPortfolioId) return

    setPortfolioId(defaultPortfolioId)
  }, [defaultPortfolioId])

  const fetchDataForWithdrawal = useApi(
    `/portfolios/${portfolioId}/saxo_cash_withdrawals/new`,
    {
      method: 'GET',
      withCredentials: true
    }
  )

  const { data, loading, request: getDataForWithdrawal } = useApiRequest(
    fetchDataForWithdrawal
  )

  useEffect(() => {
    if (verificationSuccess === 'false') return
    if (!portfolioId) return
    if (isPensionPortfolio) return

    getDataForWithdrawal()
  }, [
    portfolioId,
    getDataForWithdrawal,
    isPensionPortfolio,
    verificationSuccess
  ])

  const withdrawCash = useApi(
    `/portfolios/${portfolioId}/saxo_cash_withdrawals`,
    {
      method: 'POST',
      withCredentials: true,
      errorHandling: {
        ignore: {
          client: true
        }
      }
    }
  )

  const { request: callWithdrawCash } = useApiRequest(withdrawCash)

  const {
    externalAccounts,
    availableAmount,
    availableAmountAfterFees,
    approximateYearlyFees,
    unpaidFees
  } = data || {}

  const hasOnlyOneExternalAccount =
    externalAccounts && externalAccounts.length === 1
  const externalAccountOptions =
    externalAccounts &&
    externalAccounts.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  const defaultExternalAccountId = hasOnlyOneExternalAccount
    ? externalAccountOptions[0].value
    : null

  const initialValues = {
    portfolioId: defaultPortfolioId,
    externalAccountId: defaultExternalAccountId
  }

  const handlePortfolioIdChange = (id) => setPortfolioId(id)

  const handleSubmit = async (values) => {
    const {
      amount,
      externalAccountId,
      transactionMessage,
      portfolioId: submittedPortfolioId
    } = values
    setConfirmationPageData({
      amount,
      accountFrom: formatAccountData(portfoliosOptions, submittedPortfolioId),
      accountTo: formatAccountData(externalAccountOptions, externalAccountId),
      transactionMessage
    })
    const response = await callWithdrawCash({
      portfolio: { amount, externalAccountId, transactionMessage }
    })

    const { success } = response

    setWithdrawalIsCompleted(success)

    return response
  }

  if (verificationSuccess === 'false') return <ErrorPage />

  if (isWithdrawalCompleted)
    return <ConfirmationPage data={confirmationPageData} />

  return (
    <CenteredTextSection>
      {isPensionPortfolio && (
        <Alert variant="danger">
          Du kan ikke hæve fra din pensionopsparing, før din pensionsudbetaling
          er startet. Har du spørgsmål, eller ønsker du at hæve din pension før
          tid, så kontakt os.
        </Alert>
      )}
      <Form
        className="text-left mb-6"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        labelDescriptions={labelDescriptions}
        validationSchema={validationSchema(availableAmountAfterFees)}
        enableReinitialize
      >
        <Form.FieldGroup name="portfolioId">
          <Form.Field
            inputComponent={Select}
            options={portfoliosOptions}
            onValueChange={handlePortfolioIdChange}
            {...selectProps}
          />
        </Form.FieldGroup>
        <Form.FieldGroup
          loading={loading}
          help="Du kan kun overføre til godkendte kontoer i listen. En konto bliver godkendt og tilgængelig for udbetaling så snart der bliver overført penge fra kontoen til Saxo Bank. Ønsker du dette så overfør blot 1 krone for at få den godkendt. Husk det skal være din egen konto, da Saxo Bank ikke modtager 3. parts indbetalinger."
          name="externalAccountId"
        >
          <Form.Field
            inputComponent={Select}
            options={externalAccountOptions}
            noOptionsMessage={() =>
              'Du har ikke nogen godkendte kontoer til overførelse.'
            }
            isDisabled={loading || isPensionPortfolio || !portfolioId}
            {...selectProps}
          />
        </Form.FieldGroup>
        <Form.FieldGroup
          loading={loading}
          name="amount"
          help={
            <>
              {loading ? (
                <Loader className="pl-2" />
              ) : (
                <>
                  {portfolioId && !isPensionPortfolio && (
                    <>
                      Du har på nuværende tidspunkt{' '}
                      <strong>{numberToCurrency(availableAmount)}</strong> i
                      kontanter på din konto. Husk at efterlad et mindre beløb
                      til at dække betaling af de løbende omkostninger. De
                      samlede omkostninger for de næste 12 måneder er højest{' '}
                      <strong>{numberToCurrency(approximateYearlyFees)}</strong>
                      , og dine ubetalte omkostninger er indtil videre{' '}
                      <strong>{numberToCurrency(unpaidFees)}</strong>
                    </>
                  )}
                </>
              )}
            </>
          }
        >
          <Form.Field
            as={CurrencyInput}
            disabled={loading || isPensionPortfolio || !portfolioId}
          />
        </Form.FieldGroup>
        <Form.FieldGroup loading={loading} name="transactionMessage">
          <Form.Field
            disabled={loading || isPensionPortfolio || !portfolioId}
          />
        </Form.FieldGroup>
        <div className="d-flex justify-content-center my-4">
          <Form.SubmitButton variant="primary" disabled={isPensionPortfolio}>
            Udbetal
          </Form.SubmitButton>
        </div>
      </Form>
    </CenteredTextSection>
  )
}

export default WithdrawalPage
