import Sustainability from '@nordinvestments/nord-storybook/dist/components/Sustainability'
import { selectCurrentPortfolioId } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import usePageLeaveWarning from '../../../../../hooks/usePageLeaveWarning'
import useFinishSustainabilityUpdate from '../hooks/useFinishSustainabilityUpdate'
import useSustainabilityRedirect from '../hooks/useSustainabilityRedirect'
import useUpdateSustainabilityPreference from '../hooks/useUpdateSustainabilityPreferences'
import { useSustainabilityContext } from '../Provider'

const WarningPage = () => {
  const {
    answerData,
    questionsVersion,
    preferencesOrder
  } = useSustainabilityContext()
  const updateSustainabilityPreferences = useUpdateSustainabilityPreference()
  const history = useHistory()
  const portfolioId = useSelector(selectCurrentPortfolioId)

  useSustainabilityRedirect()
  usePageLeaveWarning()

  const finishSustainabilityUpdate = useFinishSustainabilityUpdate()

  const handleSubmit = async ({ acceptLowerSustainabilityPreference }) => {
    const { data, success } = await updateSustainabilityPreferences({
      portfolio: {
        investmentProfileAttributes: {
          version: questionsVersion,
          answers: [answerData]
        },
        sustainabilityPreferencesOrder: preferencesOrder,
        acceptLowerSustainabilityPreference
      }
    })

    if (!success) {
      history.push(
        `/portefoeljer/${portfolioId}/baeredygtighedspreferencer?success=false`
      )

      return
    }

    finishSustainabilityUpdate(data)
  }

  return (
    <div className="my-3">
      <Sustainability.Warning
        onSubmit={handleSubmit}
        submitButtonProps={{ filled: true }}
        withSubmitButton
      />
    </div>
  )
}

export default WarningPage
