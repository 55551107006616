import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { formatDateTime } from '@nordinvestments/nord-storybook/dist/utilities/formatDate'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Download from 'react-feather/dist/icons/download'
import Loader from 'react-feather/dist/icons/loader'

const List = ({ documents, loading }) => {
  if (loading && !documents)
    return (
      <div className="text-center">
        <Icon icon={Loader} spin />
      </div>
    )

  if (documents.length === 0) {
    return (
      <Row className="py-3">
        <Col>
          <Text as="div" align="center">
            Du har endnu ingen rapporter. Du kan genererer en rapport nedenfor.
          </Text>
        </Col>
      </Row>
    )
  }

  return (
    <ul className="list-unstyled">
      <Row as="li" className="border-bottom">
        <Col xs={7} md={6}>
          <Text as="div" align="left" transform="uppercase" variant="secondary">
            Titel
          </Text>
        </Col>
        <Col md={3} className="d-none d-md-block">
          <Text as="div" align="left" transform="uppercase" variant="secondary">
            Oprettet
          </Text>
        </Col>
        <Col xs={5} md={3} />
      </Row>
      {documents.map(({ filename, url, lastModifiedAt }) => (
        <Row key={url} as="li" className="border-bottom py-3">
          <Col xs={7} md={6} className="overflow-hidden text-truncate">
            <Text
              as="a"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              align="left"
              className="mb-0"
            >
              {filename}
            </Text>
          </Col>
          <Col md={3} className="d-none d-md-block">
            <Text>{formatDateTime(lastModifiedAt)}</Text>
          </Col>
          <Col xs={5} md={3}>
            <IconButton
              as="a"
              size="sm"
              variant="link"
              href={url}
              icon={Download}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Hent og gem rapport
            </IconButton>
          </Col>
        </Row>
      ))}
    </ul>
  )
}

List.displayName = 'DocumentsForm.List'

List.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      folder: PropTypes.string.isRequired
    })
  ),
  loading: PropTypes.bool
}

List.defaultProps = {
  documents: undefined,
  loading: undefined
}

export default List
