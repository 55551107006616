export const preferenceLevels = {
  medium: ({ answer }) =>
    `Med udgangspunkt i din besvarelse af vores spørgsmål, hvor du har tilkendegivet, ${answer}, har vi anbefalet dig en ansvarlig portefølje.`,
  none:
    'Med udgangspunkt i din besvarelse af vores spørgsmål, hvor du har tilkendegivet, at bæredygtig investering ikke har høj prioritet for dig, har vi anbefalet dig en standard portefølje.'
}

export const preferenceLevelAliases = {
  low: 'medium'
}
