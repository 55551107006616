import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useQuery from '@nordinvestments/nord-storybook/dist/hooks/useQuery'
import React from 'react'
import ArrowRight from 'react-feather/dist/icons/arrow-right'
import { Link } from 'react-router-dom'

import CenteredTextSection from '../../../../components/CenteredTextSection'

const defaultError =
  'Der opstod et problem med dit udbetalingsanmodning. Prøv igen, eller kontakt os hvis du fortsat oplever problemer.'

const ErrorPage = () => {
  const error = useQuery('error')

  return (
    <CenteredTextSection>
      <Text as="p">{error || defaultError}</Text>
      <IconButton
        className="mb-3"
        buttonAs={Link}
        icon={ArrowRight}
        to="/konto/kontant_udbetaling/verificer"
      >
        Prøv igen
      </IconButton>
    </CenteredTextSection>
  )
}

export default ErrorPage
