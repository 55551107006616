import AlertModal from '@nordinvestments/nord-storybook/dist/components/AlertModal'
import extractProps from '@nordinvestments/nord-storybook/dist/utilities/extractProps'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useHistory } from 'react-router-dom'

import styles from './CenteredModal.module.scss'
import ModalPageContext from './ModalPageContext'

const modalHeaderPropKeys = [
  'closeButton',
  'closeLabel',
  'closeVariant',
  'modalHeaderClassName'
]

const CenteredModal = ({ header, children, backUrl, ...otherProps }) => {
  const history = useHistory()

  const [modalHeaderProps, modalProps] = extractProps(
    otherProps,
    modalHeaderPropKeys
  )

  const { withWarning } = useContext(ModalPageContext)
  const [showAlert, setShowAlert] = useState(false)

  const handleHideAlert = () => setShowAlert(false)

  const handlePageLeave = () => {
    if (!backUrl) history.goBack()
    else history.push(backUrl)
  }

  const handleHide = () => {
    if (withWarning) {
      setShowAlert(true)

      return
    }

    handlePageLeave()
  }

  return (
    <>
      <Modal
        {...modalProps}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleHide}
        backdropClassName={styles.backdropBackground}
        contentClassName={styles.modalContentBorder}
        dialogClassName={styles.customModal}
        centered
      >
        <Modal.Header
          className={modalHeaderProps.modalHeaderClassName}
          {...modalHeaderProps}
        >
          {header}
        </Modal.Header>
        <Modal.Body className={styles.modalHeight}>{children}</Modal.Body>
      </Modal>
      <AlertModal
        show={showAlert}
        onHide={handleHideAlert}
        backdrop="static"
        title="Ændringer er ikke gemt"
        text="Er du sikker på, at du vil forlade denne side? Ændringer, du har foretaget, vil ikke blive gemt."
        onAccept={handlePageLeave}
        backdropClassName={classNames(
          styles.backdropBackground,
          styles.warningModal
        )}
        acceptable
      />
    </>
  )
}

CenteredModal.propTypes = {
  backUrl: PropTypes.string,
  children: PropTypes.node,
  header: PropTypes.node
}

CenteredModal.defaultProps = {
  backUrl: undefined,
  children: undefined,
  header: undefined
}

export default CenteredModal
