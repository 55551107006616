import Form from '@nordinvestments/nord-storybook/dist/components/Form'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import PasswordInput from '@nordinvestments/nord-storybook/dist/components/PasswordInput'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useApi from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import React from 'react'
import Button from 'react-bootstrap/Button'
import ArrowLeft from 'react-feather/dist/icons/arrow-left'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import usePageLeaveWarning from '../../../../hooks/usePageLeaveWarning'

import validationSchema from './validationSchema'

const labelDescriptions = {
  currentPassword: 'Aktuel adgangskode',
  password: 'Ny adgangskode',
  passwordConfirmation: 'Bekræft ny adgangskode'
}

const ChangePassword = () => {
  const history = useHistory()
  const { addToast } = useToasts()

  usePageLeaveWarning()

  const handleGoBack = () => history.push('/konto')

  const updateUser = useApi(`/users`, {
    method: 'PATCH',
    withCredentials: true
  })

  const handleSubmit = async (values, formik) => {
    const {
      data: { errors }
    } = await updateUser({
      user: values
    })

    if (errors) {
      formik.setErrors(errors)

      return
    }

    addToast('Din adgangskode er nu redigeret.', {
      appereance: 'info',
      autoDismiss: true
    })
    handleGoBack()
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <Button variant="link-dark" className="px-0 my-auto mr-auto">
          <Icon icon={ArrowLeft} onClick={handleGoBack} size="20" />
        </Button>
        <Text as="h4" align="center" className="my-3 mr-auto">
          Skift adgangskode
        </Text>
      </div>
      <CenteredTextSection>
        <Form
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          labelDescriptions={labelDescriptions}
          className="text-left"
          validateOnMount
        >
          <Form.FieldGroup name="currentPassword">
            <Form.Field inputComponent={PasswordInput} autoFocus />
          </Form.FieldGroup>
          <Form.FieldGroup name="password">
            <Form.Field inputComponent={PasswordInput} />
          </Form.FieldGroup>
          <Form.FieldGroup name="passwordConfirmation">
            <Form.Field inputComponent={PasswordInput} />
          </Form.FieldGroup>
          <div className="d-flex justify-content-center my-4">
            <Form.SubmitButton variant="primary" disableOnInvalid>
              Skift adgangskode
            </Form.SubmitButton>
          </div>
        </Form>
      </CenteredTextSection>
    </>
  )
}

export default ChangePassword
