import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import PropTypes from 'prop-types'
import React from 'react'
import Nav from 'react-bootstrap/Nav'
import X from 'react-feather/dist/icons/x'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'

const CloseNavItem = ({ backUrl }) => (
  <Nav.Item className={styles.closeNavItem}>
    <Nav.Link as={Link} to={backUrl} className="link-dark">
      <Icon icon={X} data-cy="close-button" />
    </Nav.Link>
  </Nav.Item>
)

CloseNavItem.propTypes = {
  backUrl: PropTypes.string.isRequired
}

export default CloseNavItem
