import { selectCurrentPortfolioId } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useSustainabilityContext } from '../Provider'

const useSustainabilityRedirect = () => {
  const { answerData } = useSustainabilityContext()
  const history = useHistory()
  const portfolioId = useSelector(selectCurrentPortfolioId)

  useEffect(() => {
    if (answerData) return

    history.push(`/portefoeljer/${portfolioId}/baeredygtighedspreferencer`)
  }, [answerData, history, portfolioId])
}

export default useSustainabilityRedirect
