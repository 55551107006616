import IntroPage from './IntroPage'
import Provider from './Provider'
import QuestionPage from './QuestionPage'
import SortingPage from './SortingPage'
import WarningPage from './WarningPage'

const Sustainability = {
  IntroPage,
  Provider,
  QuestionPage,
  SortingPage,
  WarningPage
}

export default Sustainability
