import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import DescriptionList from '@nordinvestments/nord-storybook/dist/components/DescriptionList'
import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import useApi, {
  useApiRequest
} from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectPortfolioById } from '@nordinvestments/nord-storybook/dist/store/current/portfolios'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import { Link, useParams } from 'react-router-dom'

const unsuccessfulStatuses = ['failed', 'cancelled']

const alertVariants = {
  failed: 'danger',
  cancelled: 'danger',
  succeeded: 'success',
  pending: 'warning'
}

const ResultPage = () => {
  const { paymentId } = useParams()

  const fetchPayment = useApi(`/payments/${paymentId}`, {
    method: 'GET',
    withCredentials: true
  })

  const { data: paymentData, called: paymentDataIsCalled } = useApiRequest(
    fetchPayment,
    {
      autoCall: !!paymentId
    }
  )

  const { status, message, amount, portfolioId } = paymentData || {}
  const { title, registrationNumber, saxoAccountNumber } = useObjectSelector(
    selectPortfolioById(portfolioId)
  )
  const portfolioInformation = `${title}: ${registrationNumber} - ${saxoAccountNumber}`

  return (
    <>
      {!paymentDataIsCalled && <Loader className="font-size-lg" />}
      {!isEmpty(paymentData) && (
        <Alert variant={alertVariants[status]} className="mt-3">
          <div className="d-flex flex-row justify-content-center">
            {message}
          </div>
        </Alert>
      )}
      {status && status === 'succeeded' && (
        <Card className="my-3">
          <Card.Body className="mx-lg-5">
            <DescriptionList className="text-center text-lg-left">
              <DescriptionList.Item
                label="Til konto"
                value={portfolioInformation}
                xs={12}
                lg={6}
              />
              <DescriptionList.Item
                label="Beløb"
                value={numberToCurrency(amount)}
                xs={12}
                lg={6}
              />
            </DescriptionList>
          </Card.Body>
        </Card>
      )}
      {status && unsuccessfulStatuses.includes(status) && (
        <BaffleButton as={Link} to="/konto/kontant_indbetaling/opret">
          Prøv igen
        </BaffleButton>
      )}
    </>
  )
}

export default ResultPage
