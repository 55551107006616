import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'

const {
  simple: defaultMinimumInvestment,
  pension: pensionMinimumInvestment
} = getConfig('numbers.minimumInvestment')

const useDepositReceivedData = () => {
  const { currentDepositInDkk, pensionDepot } = useObjectSelector(
    selectCurrentPortfolio
  )

  const minimumInvestment = pensionDepot
    ? pensionMinimumInvestment
    : defaultMinimumInvestment

  const depositIsEligibeToInvest = currentDepositInDkk >= minimumInvestment

  const message = depositIsEligibeToInvest
    ? 'Vi har modtaget din indbetaling og vil investere dine penge for dig hurtigst muligt, senest inden for to hverdage efter vi har modtaget overførslen.'
    : `Vi har modtaget din indbetaling, men dit kontantbeløb på din konto er desværre ikke nok til at starte din investering. Der kræves minimum ${numberToCurrency(
        minimumInvestment
      )} før vi investere for dig første gang.`

  return { title: 'Din indbetaling er modtaget', message }
}

export default useDepositReceivedData
