import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import DescriptionList from '@nordinvestments/nord-storybook/dist/components/DescriptionList'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import PropTypes from 'prop-types'
import React from 'react'

import CenteredTextSection from '../../../../components/CenteredTextSection'

import labelDescriptions from './labelDecriptions'

const ConfirmationPage = ({ data }) => {
  const { amount, transactionMessage, accountFrom, accountTo } = data

  return (
    <CenteredTextSection>
      <Text as="p">
        Vi har modtaget din overførselsanmodning og vil behandle den hurtigst
        muligt. Denne overførsel kan tage op til 5 bankdage, men det går ofte
        hurtigere.
      </Text>
      <Card className="mb-3 mx-lg-5">
        <Card.Body className="mx-lg-5">
          <DescriptionList className="text-center text-lg-left">
            <DescriptionList.Item
              label={labelDescriptions.portfolioId}
              value={accountFrom}
              xs={12}
              lg={6}
            />
            <DescriptionList.Item
              label={labelDescriptions.externalAccountId}
              value={accountTo}
              xs={12}
              lg={6}
            />
            <DescriptionList.Item
              label={labelDescriptions.amount}
              value={numberToCurrency(amount)}
              xs={12}
              lg={6}
            />
            {transactionMessage && (
              <DescriptionList.Item
                label={labelDescriptions.transactionMessage}
                value={transactionMessage}
                xs={12}
                lg={6}
              />
            )}
          </DescriptionList>
        </Card.Body>
      </Card>
    </CenteredTextSection>
  )
}

ConfirmationPage.propTypes = {
  data: PropTypes.shape().isRequired
}

export default ConfirmationPage
