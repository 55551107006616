import BooleanInput from '@nordinvestments/nord-storybook/dist/components/BooleanInput'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import {
  selectCurrentPortfolio,
  updateCurrentPortfolio
} from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { selectCurrentUser } from '@nordinvestments/nord-storybook/dist/store/current/user'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CenteredTextSection from '../../../../components/CenteredTextSection'

import styles from './index.module.scss'

const OrderHandling = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { firstName, lastName } = useSelector(selectCurrentUser)
  const { orderHandlingAccepted, fullRiskScoreTitle } = useObjectSelector(
    selectCurrentPortfolio
  )

  const handleOrderHandlingChange = async (value) => {
    setLoading(true)

    await dispatch(
      updateCurrentPortfolio({
        orderHandlingAccepted: value
      })
    )
    setLoading(false)
  }

  return (
    <>
      <Text as="h4" align="center" className="my-3">
        Forhåndsgodkendelse af indbetaling
      </Text>
      <>
        <CenteredTextSection>
          <p>
            Vi er glade for nye indbetalinger. I forhold til lovgivningen, så
            skal vi have din accept før vi må oprette værdipapirordrer for dig.
            Vi har derfor gjort det nemt for dig og tilføjet det i appen, så du
            ikke får e-mails ved hver indbetaling.
          </p>
          <p>
            Jeg, {firstName} {lastName}, bekræfter, at mine indbetalinger
            investeres i de værdipapirer jeg allerede ejer og med en fordeling
            efter min anbefalede/valgte risikoprofil {fullRiskScoreTitle}.
          </p>
          <p>
            Ændring af risikoprofil og investering i andre værdipapirer end dem
            du allerede ejer, er ikke omfattet af denne forhåndsgodkendelse. Du
            kan til enhver tid trække din accept tilbage.
          </p>
        </CenteredTextSection>
        <div className="d-flex justify-content-center py-3">
          <BooleanInput
            className={styles.switchInput}
            name="orderHandlingAcceptance"
            type="switch"
            label="Aktiver forhåndsgodkendelse"
            disabled={loading}
            checked={orderHandlingAccepted}
            onCheckedChange={handleOrderHandlingChange}
          />
        </div>
      </>
    </>
  )
}

export default OrderHandling
