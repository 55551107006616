import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import TimeoutProgressBar from '@nordinvestments/nord-storybook/dist/components/TimeoutProgressBar'
import useApi from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import useInterval from '@nordinvestments/nord-storybook/dist/hooks/useInterval'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Download from 'react-feather/dist/icons/download'

const DEFAULT_TIMEOUT_LIMIT = 30000

const Invoice = ({ amount, name, number, onTimeoutError }) => {
  const [loading, setLoading] = useState(false)
  const [downloadable, setDownloadable] = useState(false)
  const [invoiceUrl, setInvoiceUrl] = useState()

  const timeoutCallback = useCallback(() => {
    onTimeoutError(true)
    setLoading(false)
  }, [onTimeoutError])

  const downloadSingleInvoice = useApi(`/invoices/${number}/download`, {
    method: 'POST',
    withCredentials: true
  })

  const fetchSingleInvoice = useApi(`/invoices/${number}`, {
    method: 'GET',
    withCredentials: true
  })

  const handleResponse = (success, downloaded, cloudFile) => {
    if (!success) {
      setLoading(false)

      return
    }

    if (!downloaded) return

    const { url } = cloudFile

    setLoading(false)
    setDownloadable(true)
    setInvoiceUrl(url)
  }

  const handleDownload = async () => {
    setLoading(true)
    onTimeoutError(false)
    const {
      data: { downloaded, cloudFile },
      success
    } = await downloadSingleInvoice()

    handleResponse(success, downloaded, cloudFile)
  }

  const handleCallSingleInvoice = useCallback(async () => {
    const {
      data: { downloaded, cloudFile },
      success
    } = await fetchSingleInvoice()

    handleResponse(success, downloaded, cloudFile)
  }, [fetchSingleInvoice])

  useInterval(handleCallSingleInvoice, {
    duration: 5000,
    enable: loading,
    timeout: {
      limit: DEFAULT_TIMEOUT_LIMIT,
      callback: timeoutCallback
    }
  })

  return (
    <>
      <Col xs={9} lg={4} className="text-left">
        {name}
      </Col>
      <Col xs={3} lg={4} className="text-right text-lg-center">
        Beløb: {numberToCurrency(amount)}
      </Col>
      <Col className="text-left text-lg-right mt-2 mt-lg-0">
        {downloadable ? (
          <IconButton
            variant="link"
            href={invoiceUrl}
            target="_blank"
            className="py-0 px-0"
            icon={Download}
          >
            Hent faktura
          </IconButton>
        ) : (
          <Button
            variant="link"
            className="py-0 px-0"
            onClick={() => handleDownload()}
            disabled={loading}
          >
            Generer faktura
          </Button>
        )}
        {loading && (
          <div className="d-inline-block w-50 ml-5">
            <TimeoutProgressBar timeoutLimit={DEFAULT_TIMEOUT_LIMIT} />
          </div>
        )}
      </Col>
    </>
  )
}

Invoice.propTypes = {
  amount: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  onTimeoutError: PropTypes.func.isRequired
}

export default Invoice
