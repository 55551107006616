import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import PropTypes from 'prop-types'
import React from 'react'

const HistoricalValues = ({ liveData, isLoading, ...otherProps }) => (
  <div {...otherProps}>
    <Text as="h6" align="center">
      Porteføljeværdi
    </Text>
    {isLoading ||
    liveData.value === undefined ||
    liveData.return === undefined ? (
      <Loader className="font-size-xxl text-white text-center" />
    ) : (
      <>
        <Text as="h1" align="center" variant="white" className="mb-0">
          {numberToCurrency(liveData.value)}
        </Text>
        <Text as="h6" align="center">
          <span>Afkast </span>
          <Text variant="white">{numberToCurrency(liveData.return)}</Text>
        </Text>
      </>
    )}
  </div>
)

HistoricalValues.propTypes = {
  liveData: PropTypes.shape({
    value: PropTypes.number.isRequired,
    return: PropTypes.number.isRequired
  }),
  isLoading: PropTypes.bool.isRequired
}

HistoricalValues.defaultProps = {
  liveData: undefined
}

export default HistoricalValues
