import { useRouteMatch } from 'react-router-dom'

const usePortfolioInformationSubpage = () => {
  const routeMatch = useRouteMatch('/portefoeljer/:id/:subpage')

  let portfolioSubpage
  if (routeMatch && routeMatch.params.subpage)
    portfolioSubpage = routeMatch.params.subpage

  return portfolioSubpage
}

export default usePortfolioInformationSubpage
