import FullPageTransition from '@nordinvestments/nord-storybook/dist/components/FullPageTransition'
import FullScreenSection from '@nordinvestments/nord-storybook/dist/components/FullScreenSection'
import NordHeader from '@nordinvestments/nord-storybook/dist/components/Header'
import PageSwitchTransition from '@nordinvestments/nord-storybook/dist/components/PageSwitchTransition'
import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import CreatePasswordPage from './CreatePasswordPage'
import ForgotPasswordPage from './ForgotPasswordPage'
import LoginPage from './LoginPage'
import ResetPasswordPage from './ResetPasswordPage'

const NotAuthPages = () => {
  const location = useLocation()
  const { pathname: currentPath } = location

  return (
    <PageSwitchTransition pageKey={currentPath} leave>
      <FullPageTransition>
        <FullScreenSection topSection>
          <NordHeader mobile>
            <Navbar role="banner">
              <NordHeader.Logo />
            </Navbar>
          </NordHeader>
          <Switch location={location}>
            <Route path="/brugere/logind" component={LoginPage} exact />
            <Route
              path="/brugere/adgangskode/glemt"
              component={ForgotPasswordPage}
              exact
            />
            <Route
              path="/brugere/adgangskode/nulstil"
              component={ResetPasswordPage}
              exact
            />
            <Route
              path="/brugere/adgangskode/opret"
              component={CreatePasswordPage}
              exact
            />
            <Redirect
              from="*"
              to={{
                pathname: '/brugere/logind',
                search: currentPath !== '/' && `?redirect_to=${currentPath}`
              }}
            />
          </Switch>
        </FullScreenSection>
      </FullPageTransition>
    </PageSwitchTransition>
  )
}

export default NotAuthPages
