import { selectCurrentPortfolioId } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import useUpdateUserAndPortfolio from './useUpdateUserAndPortfolio'

const useFinishSustainabilityUpdate = () => {
  const history = useHistory()
  const portfolioId = useSelector(selectCurrentPortfolioId)
  const { addToast } = useToasts()
  const updateUserAndPortfolio = useUpdateUserAndPortfolio()

  const finishSustainabilityUpdate = (data) => {
    updateUserAndPortfolio(data)
    history.push(`/portefoeljer/${portfolioId}/baeredygtighedspreferencer`)

    addToast('Vi har opdateret din baeredygtighedspreferencer.', {
      appearance: 'info',
      autoDismiss: true
    })
  }

  return finishSustainabilityUpdate
}

export default useFinishSustainabilityUpdate
