import useQuery from '@nordinvestments/nord-storybook/dist/hooks/useQuery'
import classNames from 'classnames'
import {
  format,
  startOfYear,
  sub,
  differenceInBusinessDays,
  subYears
} from 'date-fns'
import React, { useContext } from 'react'

import AppContext from '../../../../context/AppContext'

import CustomDateRangeButton from './CustomDateRangeButton'
import DateButton from './DateButton'
import styles from './index.module.scss'

const DateSelection = () => {
  const { onChangeDate, dateFrom, dateTo } = useContext(AppContext)

  const currentDate = new Date()

  const differenceInBusinessDaysFromNewYear = differenceInBusinessDays(
    currentDate,
    startOfYear(currentDate)
  )

  const shouldShowCurrentYear = differenceInBusinessDaysFromNewYear > 2

  const currentYearData = {}

  if (shouldShowCurrentYear) {
    currentYearData.label = format(currentDate, 'yyyy')
    currentYearData.value = format(startOfYear(currentDate), 'yyyy-MM-dd')
  } else {
    currentYearData.label = format(subYears(currentDate, 1), 'yyyy')
    currentYearData.value = format(
      startOfYear(subYears(currentDate, 1)),
      'yyyy-MM-dd'
    )
  }

  const dates = [
    ['1m', format(sub(currentDate, { months: 1 }), 'yyyy-MM-dd')],
    ['3m', format(sub(currentDate, { months: 3 }), 'yyyy-MM-dd')],
    ['6m', format(sub(currentDate, { months: 6 }), 'yyyy-MM-dd')],
    ['1år', format(sub(currentDate, { years: 1 }), 'yyyy-MM-dd')],
    [currentYearData.label, currentYearData.value],
    ['alle', undefined]
  ]

  const handleSelectDate = (newDate) => onChangeDate(newDate)

  const queryParams = useQuery()
  const { to: queryTo } = queryParams
  const datesFrom = dates.map((date) => date[1])
  const isCustomDateRangeActive = !datesFrom.includes(dateFrom) || queryTo

  return (
    <div className="position-relative">
      <div className={styles.dateSelectionWrapper}>
        <div className="container-fluid mx-lg-3 d-flex flex-row justify-content-center justify-content-lg-start">
          {dates.map(([label, date]) => (
            <DateButton
              key={label}
              onClick={() => handleSelectDate(date)}
              active={date === dateFrom && !dateTo}
              className={classNames({
                'd-none d-lg-block': label === format(currentDate, 'yyyy')
              })}
            >
              {label}
            </DateButton>
          ))}
          <CustomDateRangeButton active={isCustomDateRangeActive} />
        </div>
      </div>
    </div>
  )
}

export default DateSelection
