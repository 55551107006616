import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import { PageSwitchContext } from '@nordinvestments/nord-storybook/dist/components/PageSwitchTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import useApi, {
  useApiRequest
} from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import useQuery from '@nordinvestments/nord-storybook/dist/hooks/useQuery'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import ArrowRight from 'react-feather/dist/icons/arrow-right'
import { Link } from 'react-router-dom'

import PasswordForm from '../PasswordForm'

const CreatePasswordLink = () => (
  <>
    <Link to="/brugere/logind?createPassword=true">
      Få tilsendt en ny email
    </Link>
    .
  </>
)

const alertTypes = {
  passwordCreated: {
    variant: 'success',
    message: 'Du kan nu logge ind med din nye adgangskode.',
    includeLink: false
  },
  invalidToken: {
    variant: 'danger',
    message:
      'Linket til at oprette din adgangskode er udløbet eller ugyldigt. Sørg for at brug linket i den seneste email du har modtaget.',
    includeLink: true
  },
  noToken: {
    variant: 'danger',
    message:
      'Du kan kun få adgang til denne side, igennem et link du modtager på email. Hvis du allerede har modtaget denne email så sørg for at bruge den fulde URL, der er angivet.',
    includeLink: true
  }
}

const onboardingUrl = getConfig('urls.onboarding.signIn')

const CreatePasswordForm = () => {
  const [alert, setAlert] = useState({})
  const createPasswordToken = useQuery('createPasswordToken')
  const { active } = useContext(PageSwitchContext)

  const createPassword = useApi('/users/password', {
    method: 'PATCH',
    errorHandling: {
      ignore: {
        client: true
      }
    }
  })

  const {
    success: responseIsSuccess,
    request: callCreatePassword
  } = useApiRequest(createPassword)

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    const {
      success,
      data: { errors }
    } = await callCreatePassword({
      user: {
        resetPasswordToken: createPasswordToken,
        ...values
      }
    })

    if (success) {
      resetForm({
        values: {
          password: '',
          passwordConfirmation: ''
        }
      })
      setAlert(alertTypes.passwordCreated)
    } else {
      setAlert(alertTypes.invalidToken)
    }

    if (errors) setErrors(errors)
  }

  useEffect(() => {
    if (createPasswordToken || active) return

    setAlert(alertTypes.noToken)
  }, [active, createPasswordToken])

  return (
    <>
      <Text as="h2" align="center" size="xl" className="mb-5">
        Opret din adgangskode
      </Text>
      {!isEmpty(alert) && (
        <Alert variant={alert.variant} className="text-center">
          {alert.message}
          {alert.includeLink && (
            <>
              <br />
              <CreatePasswordLink />
            </>
          )}
        </Alert>
      )}
      {responseIsSuccess ? (
        <div className="d-flex justify-content-center">
          <IconButton icon={ArrowRight} href={onboardingUrl}>
            Gå til log ind
          </IconButton>
        </div>
      ) : (
        <PasswordForm
          buttonText="Opret din adgangskode"
          disabledInputs={!createPasswordToken}
          onSubmit={handleSubmit}
        />
      )}
    </>
  )
}

export default CreatePasswordForm
