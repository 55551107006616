import Sustainability from '@nordinvestments/nord-storybook/dist/components/Sustainability'
import { selectCurrentPortfolioId } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import usePageLeaveWarning from '../../../../../hooks/usePageLeaveWarning'
import useFinishSustainabilityUpdate from '../hooks/useFinishSustainabilityUpdate'
import useSustainabilityRedirect from '../hooks/useSustainabilityRedirect'
import useUpdateSustainabilityPreference from '../hooks/useUpdateSustainabilityPreferences'
import { useSustainabilityContext } from '../Provider'

const Wrapper = ({ children }) => <div className="my-3">{children}</div>

Wrapper.propTypes = {
  children: PropTypes.node.isRequired
}

const SortingPage = () => {
  const history = useHistory()
  const portfolioId = useSelector(selectCurrentPortfolioId)

  const {
    answerData,
    questionsVersion,
    setPreferencesOrder
  } = useSustainabilityContext()

  useSustainabilityRedirect()
  usePageLeaveWarning()

  const updateSustainabilityPreferences = useUpdateSustainabilityPreference()
  const finishSustainabilityUpdate = useFinishSustainabilityUpdate()

  const handleSubmit = async (newSustainabilityPreferencesOrder) => {
    const { data, success } = await updateSustainabilityPreferences({
      portfolio: {
        investmentProfileAttributes: {
          version: questionsVersion,
          answers: [answerData]
        },
        sustainabilityPreferencesOrder: newSustainabilityPreferencesOrder
      }
    })

    if (success) {
      finishSustainabilityUpdate(data)

      return
    }

    setPreferencesOrder(newSustainabilityPreferencesOrder)
    history.push(
      `/portefoeljer/${portfolioId}/baeredygtighedspreferencer/advarsel`
    )
  }

  return (
    <Sustainability.Sorting
      wrapperComponent={Wrapper}
      submitButtonProps={{ filled: true }}
      onSubmit={handleSubmit}
      withSubmitButton
    />
  )
}

export default SortingPage
