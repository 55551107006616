import Form from '@nordinvestments/nord-storybook/dist/components/Form'
import PasswordInput from '@nordinvestments/nord-storybook/dist/components/PasswordInput'
import PropTypes from 'prop-types'
import React from 'react'

import validationSchema from './validationSchema'

const labelDescriptions = {
  password: 'Ny adgangskode',
  passwordConfirmation: 'Bekræft ny adgangskode'
}

const PasswordForm = ({ buttonText, disabledInputs, ...otherProps }) => (
  <Form
    validationSchema={validationSchema}
    labelDescriptions={labelDescriptions}
    validateOnMount
    {...otherProps}
  >
    <Form.FieldGroup name="password">
      <Form.Field
        inputComponent={PasswordInput}
        disabled={disabledInputs}
        autoFocus
      />
    </Form.FieldGroup>
    <Form.FieldGroup name="passwordConfirmation">
      <Form.Field inputComponent={PasswordInput} disabled={disabledInputs} />
    </Form.FieldGroup>
    <div className="d-flex justify-content-center my-4">
      <Form.SubmitButton variant="primary" disableOnInvalid>
        {buttonText}
      </Form.SubmitButton>
    </div>
  </Form>
)

PasswordForm.propTypes = {
  buttonText: PropTypes.string.isRequired,
  disabledInputs: PropTypes.bool.isRequired
}

export default PasswordForm
