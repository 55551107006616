import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentUser } from '@nordinvestments/nord-storybook/dist/store/current/user'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import AccountInformation from './AccountInformation'
import useDepositReceivedData from './useDepositReceivedData'

const useStateMessageData = () => {
  const { firstName } = useObjectSelector(selectCurrentUser)
  const {
    title: depositReceivedTitle,
    message: depositReceivedMessage
  } = useDepositReceivedData()

  const data = useMemo(
    () => ({
      payoutAccount: {
        title: 'Pension udbetalingskonto',
        message: (
          <>
            Dette er din udbetalingskonto for pension. Dvs. at når din pension
            udbetales, så overføres beløbet til denne konto. Herfra kan du
            overføre pengene til din egen bank.
            <br />
            <Link to="/konto/kontant_udbetaling/verificer">
              Gå til bank overførsel
            </Link>
          </>
        )
      },
      readyToOnboard: {
        title: 'Tak for din oprettelse',
        message:
          'Din portefølje oprettelse er under behandling. Vi sender dig en email når dit depot er klar til indbetaling.'
      },
      createdInSaxo: {
        title: 'Du kan nu overføre til din konto',
        message: (
          <>
            Din konto er oprettet og du kan nu overføre penge til din konto.
            <br /> Så snart indbetalingen er sket, bliver midlerne investeret og
            du kan følge din investering her.
            <br /> Bemærk, at der kan gå op til 2 hverdage.
          </>
        ),
        children: <AccountInformation />
      },
      depositReceived: {
        title: depositReceivedTitle,
        message: depositReceivedMessage
      },
      oldInvested: {
        title: 'Periode for kort',
        message:
          'Perioden du har valgt, har desværre ikke nok data til at vise en graf.'
      },
      newInvested: {
        title: `Hej ${firstName}`,
        message: (
          <>
            Normalvis ville der være en graf over din investering her. <br />
            Men siden vi først lige har investeret for dig, så har vi ikke så
            meget at vise - endnu.
          </>
        )
      }
    }),
    [depositReceivedTitle, depositReceivedMessage, firstName]
  )

  return data
}

export default useStateMessageData
