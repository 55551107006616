import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import DescriptionList from '@nordinvestments/nord-storybook/dist/components/DescriptionList'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import React from 'react'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import useIsPayoutAccount from '../../../../hooks/useIsPayoutAccount'

import SuitabilityContent from './SuitabilityContent'

const getOnboardingResultUrl = getConfig(
  'dynamicUrls.onboarding.investmentPlanResult'
)

const Information = () => {
  const {
    id: portfolioId,
    riskScoreTitle,
    title: portfolioTitle,
    registrationNumber,
    saxoAccountNumber,
    onboardingFlow,
    totalValue,
    cashInDkk
  } = useObjectSelector(selectCurrentPortfolio)

  const changeRiskProfileUrl = getOnboardingResultUrl(
    onboardingFlow,
    portfolioId
  )

  const isPayoutAccount = useIsPayoutAccount()

  return (
    <>
      <Text as="h4" align="center" className="mt-3">
        {portfolioTitle}
      </Text>
      <div className="text-center mb-3">
        <Link to={`/portefoeljer/${portfolioId}/rediger`}>
          (Omdøb portefølje)
        </Link>
      </div>
      <Card className="shadow mb-3 mx-lg-5">
        <Card.Body className="mx-lg-5">
          <DescriptionList className="text-center text-lg-left">
            <DescriptionList.Item
              label="Samlet værdi"
              value={numberToCurrency(totalValue)}
              xs={12}
              lg={6}
            />
            <DescriptionList.Item
              label="Heraf kontantbeholdning"
              value={numberToCurrency(cashInDkk)}
              xs={12}
              lg={6}
            />
            {!isPayoutAccount && (
              <DescriptionList.Item
                label="Risikoprofil"
                value={riskScoreTitle}
                xs={12}
                lg={6}
              />
            )}
            <DescriptionList.Item
              label="Reg.- og kontonummer"
              value={
                registrationNumber && saxoAccountNumber
                  ? `${registrationNumber} - ${saxoAccountNumber}`
                  : '-'
              }
              xs={12}
              lg={6}
            />
          </DescriptionList>
        </Card.Body>
      </Card>
      {!isPayoutAccount && (
        <CenteredTextSection className="py-1">
          <Text as="p" className="mb-0">
            Du kan til enhver tid se din investeringsplan ved at følge linket
            nedenfor. Her kan du også ændre din risikoprofil.
          </Text>
          <Button variant="link" as="a" href={changeRiskProfileUrl}>
            Se din investeringsplan eller skift din risikoprofil
          </Button>
        </CenteredTextSection>
      )}
      <SuitabilityContent />
    </>
  )
}

export default Information
