import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import styleConfiguration from '@nordinvestments/nord-storybook/dist/configuration/styleConfiguration'
import { numberToPercent } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { Pie, PieChart, Cell, Tooltip } from 'recharts'

import { ASSETS_TYPE } from '../../../../configuration/constants'

import { useAllocationContext } from './AllocationContext'

const { blues } = styleConfiguration

const Chart = () => {
  const { chartData } = useAllocationContext()

  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 100
  })

  return (
    <div ref={ref}>
      <PieChart width={width} height={width} className="mx-auto mx-lg-0">
        <Pie data={chartData} isAnimationActive={false} startAngle={-270}>
          {chartData.map((entry, index) => (
            <Cell
              fill={blues[(index + 1) * 100]}
              stroke={blues[(index + 1) * 100]}
            />
          ))}
        </Pie>
        <Tooltip
          cursor={false}
          content={({ payload }) => {
            if (!payload) return null
            if (!payload.length) return null
            if (!payload[0].payload) return null

            return (
              <Card>
                <Card.Body className="p-2">
                  <Card.Title className="font-size-md mb-0">
                    {capitalize(ASSETS_TYPE[payload[0].name])}
                  </Card.Title>
                  <Card.Text className="font-size-sm text-center mb-0">
                    {numberToPercent(payload[0].value, {
                      maximumFractionDigits: 2
                    })}
                  </Card.Text>
                </Card.Body>
              </Card>
            )
          }}
        />
      </PieChart>
    </div>
  )
}

export default Chart
