import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import PropTypes from 'prop-types'
import React from 'react'

const HistoricalReturns = ({ liveData, isLoading, ...otherProps }) => (
  <div {...otherProps}>
    <Text as="h6" align="center">
      Afkast
    </Text>
    <Text as="h1" align="center" variant="white" className="mb-0">
      {isLoading || liveData === undefined ? (
        <Loader />
      ) : (
        numberToCurrency(liveData.value)
      )}
    </Text>
  </div>
)

HistoricalReturns.propTypes = {
  liveData: PropTypes.shape({
    value: PropTypes.number.isRequired
  }),
  isLoading: PropTypes.bool.isRequired
}

HistoricalReturns.defaultProps = {
  liveData: undefined
}

export default HistoricalReturns
