import FullScreenSection from '@nordinvestments/nord-storybook/dist/components/FullScreenSection'
import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useApi, {
  useApiRequest
} from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import useDeepCompareEffect from '@nordinvestments/nord-storybook/dist/hooks/useDeepCompareEffect'
import useInterval from '@nordinvestments/nord-storybook/dist/hooks/useInterval'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import classNames from 'classnames'
import React, { useContext, useState } from 'react'
import Col from 'react-bootstrap/Col'

import { CHART_TYPE_ENDPOINTS } from '../../../configuration/constants'
import AppContext from '../../../context/AppContext'
import useIsPayoutAccount from '../../../hooks/useIsPayoutAccount'
import PortfolioStatePage from '../PortfolioStatePage'

import ChartContainer from './ChartContainer'
import formatDataForChart from './formatDataForChart'
import Header from './Header'
import useUpdatePortfolioReturnInDkk from './hooks/useUpdatePortfolioReturnInDkk'
import useUpdatePortfolioTotalValue from './hooks/useUpdatePortfolioTotalValue'
import styles from './index.module.scss'

const ChartPage = () => {
  const { dateFrom, dateTo, chartType } = useContext(AppContext)
  const [chartData, setChartData] = useState()

  const {
    appState: portfolioAppState,
    id: portfolioId,
    title: portfolioTitle
  } = useObjectSelector(selectCurrentPortfolio)

  const isPayoutAccount = useIsPayoutAccount()
  const isInvested = portfolioAppState === 'invested'
  const shouldCallChartData = !isPayoutAccount && isInvested

  const fetchChartData = useApi(
    `/portfolios/${portfolioId}/charts/${CHART_TYPE_ENDPOINTS[chartType]}`,
    {
      withCredentials: true
    }
  )

  const { data: rawChartData, called: isChartDataCalled } = useApiRequest(
    fetchChartData,
    {
      autoCall: shouldCallChartData,
      payload: {
        from: dateFrom,
        to: dateTo,
        newFormat: true
      }
    }
  )

  const liveDataPath = `/portfolios/${portfolioId}/charts/${CHART_TYPE_ENDPOINTS[chartType]}/live`
  const fetchChartLiveData = useApi(liveDataPath, {
    withCredentials: true
  })

  const {
    data: liveData,
    loading: loadingLiveData,
    request: updateLiveData
  } = useApiRequest(fetchChartLiveData, {
    autoCall: shouldCallChartData,
    payload: {
      from: dateFrom,
      to: dateTo,
      newFormat: true
    }
  })

  useUpdatePortfolioTotalValue(liveData, loadingLiveData)

  useUpdatePortfolioReturnInDkk(liveData, loadingLiveData)

  useInterval(updateLiveData, { duration: 60000 })

  const { formattedData, formatting: formattingChartData } = formatDataForChart(
    rawChartData,
    chartType
  )

  useDeepCompareEffect(() => {
    if (!formattingChartData) setChartData(formattedData)
  }, [formattingChartData, formattedData])

  const isChartReady =
    portfolioAppState === 'invested' && chartData && chartData.length >= 2

  if (!isChartDataCalled && shouldCallChartData)
    return (
      <FullScreenSection height="full">
        <Col>
          <Loader className="text-center font-size-xxl" />
        </Col>
      </FullScreenSection>
    )

  return (
    <div
      className={classNames(
        styles.chartWrapper,
        'd-flex',
        'flex-column',
        'justify-content-center',
        'align-items-center',
        'h-100'
      )}
    >
      <Text as="h5" align="center" className="d-none d-md-block">
        {portfolioTitle}
      </Text>
      <Header
        className={classNames(styles.dataWrapper, 'pt-2')}
        isLoading={loadingLiveData}
        liveData={liveData}
        chartType={chartType}
      />
      {isChartReady ? (
        <ChartContainer data={chartData} chartType={chartType} />
      ) : (
        <PortfolioStatePage />
      )}
    </div>
  )
}

export default ChartPage
