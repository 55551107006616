import BooleanCollection from '@nordinvestments/nord-storybook/dist/components/BooleanCollection'
import Form from '@nordinvestments/nord-storybook/dist/components/Form'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import InfoModal from '@nordinvestments/nord-storybook/dist/components/QuestionsSection/QuestionPage/InfoModal'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useApi, {
  useApiRequest
} from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ArrowLeft } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'

import usePageLeaveWarning from '../../../../../hooks/usePageLeaveWarning'
import useFinishSustainabilityUpdate from '../hooks/useFinishSustainabilityUpdate'
import useUpdateSustainabilityPreference from '../hooks/useUpdateSustainabilityPreferences'
import { useSustainabilityContext } from '../Provider'

const formatOptions = (data) =>
  data.map((item) => ({
    label: item,
    value: item
  }))

const QuestionPage = () => {
  const {
    id: portfolioId,
    sustainabilityPreferenceGoalAnswer
  } = useObjectSelector(selectCurrentPortfolio)
  const history = useHistory()

  usePageLeaveWarning()

  const {
    setQuestionsVersion,
    setAnswerData,
    answerData: newAnswerData
  } = useSustainabilityContext()

  const { answer: newAnswer } = newAnswerData || {}
  const initialAnswer = sustainabilityPreferenceGoalAnswer

  const currentAnswer = newAnswer || initialAnswer

  const fetchInvestmentPlanQuestions = useApi('/investment_profiles')

  const { data } = useApiRequest(fetchInvestmentPlanQuestions, {
    payload: {
      type: 'sustainability_preferences'
    },
    autoCall: true
  })

  const { data: sustainabilityQuestions, version } = data || {}

  const sustainabilityQuestion =
    sustainabilityQuestions && sustainabilityQuestions[0]

  const { question, answers, infoText, number } = sustainabilityQuestion || {}

  const options = answers && formatOptions(answers)

  const updateSustainabilityPreferences = useUpdateSustainabilityPreference()
  const finishSustainabilityUpdate = useFinishSustainabilityUpdate()

  useEffect(() => {
    if (!version) return

    setQuestionsVersion(version)
  }, [setQuestionsVersion, version])

  const handleSubmit = async ({ sustainabilityPreferenceGoal }) => {
    const answerData = {
      question,
      number,
      answers,
      answerIndex: answers.indexOf(sustainabilityPreferenceGoal),
      answer: sustainabilityPreferenceGoal
    }

    setAnswerData(answerData)

    const {
      data: responseData,
      success
    } = await updateSustainabilityPreferences({
      portfolio: {
        investmentProfileAttributes: {
          version,
          answers: [answerData]
        }
      }
    })

    const {
      sustainabilityPreferenceLevel,
      user: { initialSustainabilityPreferenceLevel }
    } = responseData

    if (success) {
      finishSustainabilityUpdate(responseData)

      return
    }

    if (initialSustainabilityPreferenceLevel === 'high') {
      history.push(
        `/portefoeljer/${portfolioId}/baeredygtighedspreferencer/sortering`
      )

      return
    }

    if (sustainabilityPreferenceLevel === 'high') {
      history.push(
        `/portefoeljer/${portfolioId}/baeredygtighedspreferencer/advarsel`
      )
    }
  }

  return (
    <Row className="my-3">
      {!sustainabilityQuestion ? (
        <Col>
          <Loader className="text-center font-size-lg" />
        </Col>
      ) : (
        <>
          <Col xs={12} className="d-flex">
            <Button
              as={Link}
              to={`/portefoeljer/${portfolioId}/baeredygtighedspreferencer`}
              variant="link-dark"
              className="btn-anchor pr-3"
            >
              <Icon icon={ArrowLeft} size="20" />
            </Button>
            <Text as="h4" align="center">
              {question}
            </Text>
          </Col>
          <InfoModal {...infoText} />
          <Col xs={12} lg={{ span: 8, offset: 2 }}>
            <Form
              initialValues={{ sustainabilityPreferenceGoal: currentAnswer }}
              onSubmit={handleSubmit}
            >
              <Form.FieldGroup name="sustainabilityPreferenceGoal">
                <Form.Field
                  type="radio"
                  inputComponent={BooleanCollection}
                  options={options}
                />
              </Form.FieldGroup>
              <div className="d-flex justify-content-center">
                <Form.SubmitButton variant="primary" filled>
                  Godkend
                </Form.SubmitButton>
              </div>
            </Form>
          </Col>
        </>
      )}
    </Row>
  )
}

export default QuestionPage
