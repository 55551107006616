import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import NavMenu from '@nordinvestments/nord-storybook/dist/components/NavMenu'
import Transition from '@nordinvestments/nord-storybook/dist/components/Transition'
import { selectCurrentPortfolioId } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { selectCurrentUser } from '@nordinvestments/nord-storybook/dist/store/current/user'
import classNames from 'classnames'
import React, { useState } from 'react'
import ChevronDown from 'react-feather/dist/icons/chevron-down'
import ChevronUp from 'react-feather/dist/icons/chevron-up'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import useIsPayoutAccount from '../../hooks/useIsPayoutAccount'

const ExpandableMobileMenu = () => {
  const { accountType, appState } = useSelector(selectCurrentUser)
  const portfolioId = useSelector(selectCurrentPortfolioId)
  const isPayoutAccount = useIsPayoutAccount()

  const isUserCreated = appState === 'user_created'
  const isCompany = accountType === 'company_account'

  const [showPortfolioSubMenu, setShowPortfolioSubMenu] = useState(false)
  const [showAccountSubMenu, setShowAccountSubMenu] = useState(false)

  const handleToggleShowPortfolioSubMenu = () => {
    if (showAccountSubMenu) setShowAccountSubMenu(false)
    setShowPortfolioSubMenu(!showPortfolioSubMenu)
  }

  const handleToggleShowAccountSubMenu = () => {
    if (showPortfolioSubMenu) setShowPortfolioSubMenu(false)
    setShowAccountSubMenu(!showAccountSubMenu)
  }

  return (
    <div className="d-lg-none">
      <NavMenu.Item>
        <NavMenu.Link
          onClick={handleToggleShowAccountSubMenu}
          className={classNames(
            {
              'text-white': showAccountSubMenu
            },
            'text-center'
          )}
          data-cy="min-konto"
        >
          Min konto
          <Icon
            icon={showAccountSubMenu ? ChevronUp : ChevronDown}
            className="ml-1"
          />
        </NavMenu.Link>
      </NavMenu.Item>
      <Transition.Expand in={showAccountSubMenu} key="accountSubMenu">
        <NavMenu.Item>
          <NavMenu.Link
            as={Link}
            className="font-size-xs text-center"
            to="/konto"
          >
            Mine informationer
          </NavMenu.Link>
        </NavMenu.Item>
        <NavMenu.Item>
          <NavMenu.Link
            as={Link}
            className="font-size-xs text-center"
            to="/konto/dokumenter"
          >
            Dokumenter
          </NavMenu.Link>
        </NavMenu.Item>
        <NavMenu.Item>
          <NavMenu.Link
            as={Link}
            className="font-size-xs text-center"
            to="/konto/fakturaer"
          >
            Fakturaer
          </NavMenu.Link>
        </NavMenu.Item>
        {(!isCompany || isUserCreated) && (
          <NavMenu.Item>
            <NavMenu.Link
              as={Link}
              className="font-size-xs text-center"
              to="/konto/oprettelser"
            >
              Depotoprettelser
            </NavMenu.Link>
          </NavMenu.Item>
        )}
        <NavMenu.Item>
          <NavMenu.Link
            as={Link}
            className="font-size-xs text-center"
            to="/konto/henvisning"
          >
            Henvisning
          </NavMenu.Link>
        </NavMenu.Item>
        <NavMenu.Item>
          <NavMenu.Link
            as={Link}
            className="font-size-xs text-center"
            to="/konto/adgangskode"
          >
            Skift adgangskode
          </NavMenu.Link>
        </NavMenu.Item>
      </Transition.Expand>
      {appState !== 'user_created' && (
        <NavMenu.Item>
          <NavMenu.Link
            onClick={handleToggleShowPortfolioSubMenu}
            className={classNames(
              {
                'text-white': showPortfolioSubMenu
              },
              'text-center'
            )}
            data-cy="mere"
          >
            Mere
            <Icon
              icon={showPortfolioSubMenu ? ChevronUp : ChevronDown}
              className="ml-1"
            />
          </NavMenu.Link>
        </NavMenu.Item>
      )}
      <Transition.Expand in={showPortfolioSubMenu} key="portfolioSubMenu">
        <NavMenu.Item>
          <NavMenu.Link
            as={Link}
            className="font-size-xs text-center"
            to={`/portefoeljer/${portfolioId}`}
          >
            Information
          </NavMenu.Link>
        </NavMenu.Item>
        {!isPayoutAccount && (
          <NavMenu.Item>
            <NavMenu.Link
              as={Link}
              to={`/portefoeljer/${portfolioId}/ny_indbetaling`}
              className="font-size-xs text-center"
            >
              Ny indbetaling
            </NavMenu.Link>
          </NavMenu.Item>
        )}
        <NavMenu.Item>
          <NavMenu.Link
            as={Link}
            className="font-size-xs text-center"
            to={`/portefoeljer/${portfolioId}/kontobevaegelser`}
          >
            Kontobevægelser
          </NavMenu.Link>
        </NavMenu.Item>
        {!isPayoutAccount && (
          <>
            <NavMenu.Item>
              <NavMenu.Link
                as={Link}
                className="font-size-xs text-center"
                to={`/portefoeljer/${portfolioId}/portefolje`}
              >
                Portefølje
              </NavMenu.Link>
            </NavMenu.Item>
            <NavMenu.Item>
              <NavMenu.Link
                as={Link}
                className="font-size-xs text-center"
                to={`/portefoeljer/${portfolioId}/rapporter`}
              >
                Rapporter
              </NavMenu.Link>
            </NavMenu.Item>
            <NavMenu.Item>
              <NavMenu.Link
                as={Link}
                className="font-size-xs text-center"
                to={`/portefoeljer/${portfolioId}/forhandsgodkendelse`}
              >
                Forhåndsgodkendelse
              </NavMenu.Link>
            </NavMenu.Item>
            <NavMenu.Item>
              <NavMenu.Link
                as={Link}
                className="font-size-xs text-center"
                to={`/portefoeljer/${portfolioId}/baeredygtighedspreferencer`}
              >
                Baeredygtighedspreferencer
              </NavMenu.Link>
            </NavMenu.Item>
          </>
        )}
      </Transition.Expand>
    </div>
  )
}

export default ExpandableMobileMenu
