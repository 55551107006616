import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

import CenteredModal from './CenteredModal'
import ModalPageContext from './ModalPageContext'

const ModalPage = ({ children, header, backUrl, ...otherProps }) => {
  const [withWarning, setWithWarning] = useState(false)

  const handleChangeWithWarning = useCallback(
    (value) => setWithWarning(value),
    []
  )

  const context = useMemo(
    () => ({
      withWarning,
      onChangeWithWarning: handleChangeWithWarning
    }),
    [handleChangeWithWarning, withWarning]
  )

  return (
    <ModalPageContext.Provider value={context}>
      <CenteredModal backUrl={backUrl} header={header} show {...otherProps}>
        {children}
      </CenteredModal>
    </ModalPageContext.Provider>
  )
}

ModalPage.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  backUrl: PropTypes.string
}

ModalPage.defaultProps = {
  backUrl: undefined,
  children: undefined,
  header: undefined
}

export default ModalPage
