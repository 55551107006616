import DateRangeInput from '@nordinvestments/nord-storybook/dist/components/DateRangeInput'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import LoadingButton from '@nordinvestments/nord-storybook/dist/components/LoadingButton'
import Select from '@nordinvestments/nord-storybook/dist/components/Select'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useApi from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import useDeepCompareEffect from '@nordinvestments/nord-storybook/dist/hooks/useDeepCompareEffect'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { formatISO, subYears, startOfYear, endOfYear, isBefore } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Loader from 'react-feather/dist/icons/loader'

import reportDescriptions from './reportDescriptions'

const refreshInterval = 2500
const refreshTimeout = 30000

const selectOptions = Object.entries(
  reportDescriptions
).map(([value, label]) => ({ value, label }))

const formatDate = (date) =>
  formatISO(date, {
    representation: 'date'
  })

const getDefaultDates = (minFromDate) => {
  const currentDate = new Date()
  const lastYear = subYears(currentDate, 1)
  const startOfLastYearDate = startOfYear(lastYear)
  const formattedMinFromValue = new Date(minFromDate)

  if (minFromDate && isBefore(startOfLastYearDate, formattedMinFromValue))
    return {
      from: formatDate(formattedMinFromValue),
      to: formatDate(currentDate)
    }

  return {
    from: formatDate(startOfLastYearDate),
    to: formatDate(endOfYear(lastYear))
  }
}

const ReportGenerationForm = ({ portfolioId, onGenerated }) => {
  const { createdInSaxoAt } = useObjectSelector(selectCurrentPortfolio)
  const [submitting, setSubmitting] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [hasError, setHasError] = useState(false)
  const { from: defaultFromDate, to: defaultToDate } = getDefaultDates(
    createdInSaxoAt
  )

  const [dateRangeValues, setDateRangeValues] = useState({
    from: defaultFromDate,
    to: defaultToDate
  })

  const handleDateRangeValuesChange = (value) =>
    setDateRangeValues({ ...dateRangeValues, ...value })

  const [reportType, setReportType] = useState(
    Object.keys(reportDescriptions)[0]
  )

  const currentDate = formatDate(new Date())

  const handleServerError = useCallback(() => setHasError(true), [])

  const generateReport = useApi(`/portfolios/${portfolioId}/saxo_reports`, {
    withCredentials: true,
    method: 'POST',
    errorHandling: {
      onServerError: handleServerError
    }
  })

  const fetchReport = useApi(`/portfolios/${portfolioId}/saxo_reports`, {
    withCredentials: true,
    errorHandling: {
      ignore: {
        client: true
      },
      onServerError: handleServerError
    }
  })

  const handleSelectType = (newReportType) => setReportType(newReportType)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setSubmitting(true)

    const { from, to } = dateRangeValues

    await generateReport({
      saxoReport: {
        from,
        to,
        type: reportType
      }
    })

    setHasError(false)
    setRefreshing(true)
    setSubmitting(false)
  }

  useDeepCompareEffect(() => {
    if (!refreshing) return undefined

    const startTime = new Date()

    const asyncRefresh = async () => {
      const currentTime = new Date()
      const elapsedTime = currentTime - startTime

      if (elapsedTime > refreshTimeout) {
        setRefreshing(false)
        setHasError(true)

        return
      }

      const { from, to } = dateRangeValues

      const { success: refreshSuccess } = await fetchReport({
        type: reportType,
        from,
        to
      })

      if (refreshSuccess) {
        await onGenerated()
        setRefreshing(false)
      }
    }

    const refreshIntervalId = setInterval(asyncRefresh, refreshInterval)
    asyncRefresh()

    return () => clearInterval(refreshIntervalId)
  }, [refreshing, fetchReport, onGenerated, reportType, dateRangeValues])

  const loading = refreshing || submitting

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Text as="div" align="center" className="mb-4">
            Generer forskellige konto rapporter igennem Saxo Bank.
          </Text>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {loading && (
            <Alert variant="info">
              <Text align="center" as="div">
                Genererer din rapport (dette kan tage op til et minut)...{' '}
                <Icon icon={Loader} spin />
              </Text>
            </Alert>
          )}
          {hasError && (
            <Alert variant="warning">
              <Text align="center" as="div">
                Der skete en fejl med genereringen af rapporten. Prøv igen eller
                kontakt os hvis fejlen fortsætter.
              </Text>
            </Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <Row>
            <DateRangeInput
              value={dateRangeValues}
              min={createdInSaxoAt}
              max={currentDate}
              onValueChange={handleDateRangeValuesChange}
              disabled={loading}
            />
          </Row>
        </Col>
        <Form.Group as={Col} xs={12} lg={3}>
          <Form.Label>Type</Form.Label>
          <Select
            name="reportType"
            onValueChange={handleSelectType}
            options={selectOptions}
            value={reportType}
            isDisabled={loading}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          xs={12}
          lg={3}
          className="d-flex justify-content-center justify-content-lg-start align-items-end"
        >
          <LoadingButton type="submit" loading={loading}>
            Generer rapport
          </LoadingButton>
        </Form.Group>
      </Row>
    </Form>
  )
}

ReportGenerationForm.propTypes = {
  portfolioId: PropTypes.number.isRequired,
  onGenerated: PropTypes.func.isRequired
}

export default ReportGenerationForm
