import { useEffect, useContext } from 'react'

import ModalPageContext from '../components/ModalPage/ModalPageContext'

const usePageLeaveWarning = () => {
  const { onChangeWithWarning } = useContext(ModalPageContext)

  useEffect(() => {
    onChangeWithWarning(true)

    return () => onChangeWithWarning(false)
  }, [onChangeWithWarning])
}

export default usePageLeaveWarning
