import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './DateButton.module.scss'

const DateButton = ({ children, active, className, ...props }) => (
  <BaffleButton
    variant="link"
    size="sm"
    transform="uppercase"
    className={classNames(
      styles.dateButton,
      'mr-lg-3',
      'font-size-md',
      'flex-grow-1',
      'flex-lg-grow-0',
      { 'text-warning': !active },
      { 'text-white': active },
      className
    )}
    {...props}
  >
    {children}
  </BaffleButton>
)

DateButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
}

DateButton.defaultProps = {
  active: false,
  children: undefined,
  className: undefined
}

export default DateButton
