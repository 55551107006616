import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import DescriptionList from '@nordinvestments/nord-storybook/dist/components/DescriptionList'
import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { selectCurrentUser } from '@nordinvestments/nord-storybook/dist/store/current/user'
import React from 'react'
import SettingsIcon from 'react-feather/dist/icons/settings'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Information = () => {
  const {
    firstName,
    lastName,
    email,
    phone,
    phonePrefix,
    accountType,
    companyName
  } = useSelector(selectCurrentUser)

  const isCompany = accountType === 'company_account'

  return (
    <>
      <Text as="h4" align="center" className="my-3">
        Mine informationer
      </Text>
      <Card className="mb-3 mx-lg-5">
        <Card.Body className="mx-lg-5">
          <DescriptionList className="text-center text-lg-left">
            {isCompany && (
              <DescriptionList.Item
                label="Virksomhedsnavn"
                value={companyName}
                xs={12}
                lg={6}
              />
            )}
            <DescriptionList.Item
              label="Navn"
              value={`${firstName} ${lastName}`}
              xs={12}
              lg={6}
            />
            <DescriptionList.Item label="Email" value={email} xs={12} lg={6} />
            <DescriptionList.Item
              label="Telefon"
              value={`+${phonePrefix} ${phone}`}
              xs={12}
              lg={6}
            />
          </DescriptionList>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-center my-4">
        <IconButton icon={SettingsIcon} as={Link} to="/konto/adgangskode">
          Skift adgangskode
        </IconButton>
      </div>
    </>
  )
}

export default Information
