import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { useContext, useMemo } from 'react'

import AppContext from '../../../../context/AppContext'
import useIsPayoutAccount from '../../../../hooks/useIsPayoutAccount'

const useHeaderType = () => {
  const { chartType } = useContext(AppContext)
  const { appState: portfolioAppState } = useObjectSelector(
    selectCurrentPortfolio
  )
  const isPayoutAccount = useIsPayoutAccount()
  const isInvested = portfolioAppState === 'invested'

  const headerType = useMemo(() => {
    if (isPayoutAccount || !isInvested) return 'accountValue'

    return chartType
  }, [chartType, isInvested, isPayoutAccount])

  return headerType
}

export default useHeaderType
