import AlertModal from '@nordinvestments/nord-storybook/dist/components/AlertModal'
import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import LoadingButton from '@nordinvestments/nord-storybook/dist/components/LoadingButton'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import useApi, {
  useApiRequest
} from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import classNames from 'classnames'
import camelCase from 'lodash/camelCase'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'

import styles from '../../../../components/ModalPage/CenteredModal.module.scss'

import AccountCreationsContext from './AccountCreationsContext'

const onboardingStateDescriptions = {
  investmentPlanCompleted: 'Mangler godkendelse af risikoprofil',
  riskProfileChosen: 'Mangler færdiggørelse',
  readyToOnboard: 'Under oprettelse hos NORD',
  submittedToSaxo: 'Under oprettelse hos Saxo',
  createdInSaxo: 'Færdigoprettet'
}

const getInvestmentPlanUrl = getConfig('dynamicUrls.onboarding.investmentPlan')

const PortfolioRow = ({
  onboardingFlow,
  id: portfolioId,
  title: portfolioTitle,
  readyToOnboardAt,
  onboardingState,
  showDeleteButton
}) => {
  const { refetchPortfolios } = useContext(AccountCreationsContext)
  const [showAlert, setShowAlert] = useState(false)

  const handleShowAlert = () => setShowAlert(true)
  const handleHideAlert = () => setShowAlert(false)

  const investmentPlanUrl = getInvestmentPlanUrl(onboardingFlow, portfolioId)

  const deletePortfolio = useApi(`/onboarding/${portfolioId}/portfolios`, {
    method: 'DELETE',
    withCredentials: true
  })

  const {
    request: callDeletePortfolio,
    loading: isDeletingPortfolio
  } = useApiRequest(deletePortfolio)

  const handleDeletePortfolio = async () => {
    const { success } = await callDeletePortfolio()

    if (!success) return

    await refetchPortfolios()
  }

  const formattedOnboardingState = camelCase(onboardingState)
  const stateDescription =
    onboardingStateDescriptions[formattedOnboardingState] ||
    'Mangler information'

  return (
    <>
      <li className="border-bottom py-2">
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
          <Text
            as="div"
            variant="secondary"
            transform="uppercase"
            className="mb-0 text-center text-lg-left"
          >
            {portfolioTitle}
          </Text>
          <Text as="div" align="center" className="pt-2 pt-lg-0 mb-0">
            {stateDescription}
          </Text>
          <div className="text-center text-lg-right mt-3 mt-lg-0">
            {!readyToOnboardAt && (
              <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-end">
                <BaffleButton
                  as="a"
                  href={investmentPlanUrl}
                  size="sm"
                  variant="success"
                  filled
                >
                  Fortsæt oprettelsen
                </BaffleButton>
                {showDeleteButton && (
                  <BaffleButton
                    onClick={handleShowAlert}
                    variant="danger"
                    size="sm"
                    className="mt-2 mt-lg-0 ml-lg-2"
                    filled
                  >
                    Slet oprettelsen
                  </BaffleButton>
                )}
              </div>
            )}
          </div>
        </div>
      </li>
      <AlertModal
        show={showAlert}
        onHide={handleHideAlert}
        title={`Slet ${portfolioTitle}`}
        text="Er du sikker på, at du vil slette denne oprettelse?"
        acceptButtonComponent={LoadingButton}
        acceptButtonProps={{ loading: isDeletingPortfolio }}
        onAccept={handleDeletePortfolio}
        acceptButtonText="Slet oprettelse"
        backdropClassName={classNames(
          styles.backdropBackground,
          styles.warningModal
        )}
        acceptable
      />
    </>
  )
}

PortfolioRow.defaultProps = {
  readyToOnboardAt: null
}

PortfolioRow.propTypes = {
  onboardingFlow: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  readyToOnboardAt: PropTypes.string,
  onboardingState: PropTypes.string.isRequired,
  showDeleteButton: PropTypes.bool.isRequired
}

export default PortfolioRow
