import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useApi, {
  useApiRequest
} from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import formatDate from '@nordinvestments/nord-storybook/dist/utilities/formatDate'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import usePortfolioId from '../../../../hooks/usePortfolioId'

const Transactions = () => {
  const portfolioId = usePortfolioId()

  const fetchTransactions = useApi(`/portfolios/${portfolioId}/transactions`, {
    method: 'GET',
    withCredentials: true
  })

  const {
    data: transactionsData,
    loading: loadingTransactionData
  } = useApiRequest(fetchTransactions, { autoCall: true })

  return (
    <>
      <Text as="h4" align="center" className="my-3">
        Kontobevægelser
      </Text>
      {loadingTransactionData ? (
        <Loader className="text-center font-size-xxl" />
      ) : (
        <ul className="list-unstyled px-2">
          {isEmpty(transactionsData) ? (
            <CenteredTextSection>
              Der er ingen kontobevægelser at vise.
            </CenteredTextSection>
          ) : (
            transactionsData.map(({ id, description, date, amount, saldo }) => (
              <Row key={id} as="li" className="border-bottom py-2">
                <Col>
                  <Text
                    as="div"
                    align="left"
                    transform="uppercase"
                    className="mb-0"
                  >
                    {description}
                  </Text>
                  <Text as="div" align="left" variant="secondary">
                    {formatDate(date)}
                  </Text>
                </Col>
                <Col>
                  <Text as="div" align="right" className="mb-0">
                    {numberToCurrency(amount)}
                  </Text>
                  <Text
                    as="div"
                    align="right"
                    transform="uppercase"
                    variant="secondary"
                  >
                    Saldo: {numberToCurrency(saldo)}
                  </Text>
                </Col>
              </Row>
            ))
          )}
        </ul>
      )}
    </>
  )
}

export default Transactions
