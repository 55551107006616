import Form from '@nordinvestments/nord-storybook/dist/components/Form'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import {
  selectCurrentPortfolio,
  updateCurrentPortfolio
} from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import React from 'react'
import Button from 'react-bootstrap/Button'
import ArrowLeft from 'react-feather/dist/icons/arrow-left'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import usePageLeaveWarning from '../../../../hooks/usePageLeaveWarning'

const TitleChange = () => {
  const dispatch = useDispatch()
  const { id: portfolioId, title: portfolioTitle } = useSelector(
    selectCurrentPortfolio
  )

  usePageLeaveWarning()

  const history = useHistory()
  const { addToast } = useToasts()

  const handleGoBack = () => history.push(`/portefoeljer/${portfolioId}`)

  const handleSubmit = async ({ title }) => {
    await dispatch(
      updateCurrentPortfolio({
        title
      })
    )

    addToast('Din portefølje er nu omdøbt.', {
      appearance: 'info',
      autoDismiss: true
    })
    handleGoBack()
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <Button variant="link-dark" className="px-0 my-auto mr-auto">
          <Icon icon={ArrowLeft} onClick={handleGoBack} size="20" />
        </Button>
        <Text as="h4" align="center" className="my-3 mr-auto">
          Omdøb portefølje
        </Text>
      </div>
      <CenteredTextSection>
        <Form
          onSubmit={handleSubmit}
          initialValues={{ title: portfolioTitle }}
          className="text-left"
        >
          <Form.FieldGroup label="Portefølje titel" name="title">
            <Form.Field autoFocus />
          </Form.FieldGroup>
          <div className="d-flex justify-content-center my-4">
            <Form.SubmitButton variant="primary">Omdøb</Form.SubmitButton>
          </div>
        </Form>
      </CenteredTextSection>
    </>
  )
}

export default TitleChange
