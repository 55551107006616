import useBreakpointHandler from '@nordinvestments/nord-storybook/dist/hooks/useBreakpointHandler'
import useDeepCompareCallback from '@nordinvestments/nord-storybook/dist/hooks/useDeepCompareCallback'
import useIsSignedIn from '@nordinvestments/nord-storybook/dist/hooks/useIsSignedIn'
import useQuery from '@nordinvestments/nord-storybook/dist/hooks/useQuery'
import { selectInitialLoading } from '@nordinvestments/nord-storybook/dist/store/current/loading'
import {
  setCurrentPortfolioId,
  selectCurrentPortfolioId
} from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { selectPortfolioIds } from '@nordinvestments/nord-storybook/dist/store/current/portfolios'
import callIntercom from '@nordinvestments/nord-storybook/dist/utilities/callIntercom'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'

import { CHART_TYPE_PATHS } from '../configuration/constants'
import AppContext from '../context/AppContext'
import useChartType from '../hooks/useChartType'
import usePortfolioId from '../hooks/usePortfolioId'
import AuthPages from '../pages/AuthPages'
import LoadingPage from '../pages/LoadingPage'
import NotAuthPages from '../pages/NotAuthPages'

const defaultChartTypePath = 'historisk'
const defaultChartType = CHART_TYPE_PATHS[defaultChartTypePath]

const PagesWrapper = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const queryParams = useQuery()
  const chartTypePath = useChartType()
  const routeId = usePortfolioId()
  const isSignedIn = useIsSignedIn()
  const allowedDeviceSize = useBreakpointHandler('lg')

  const { from: queryFrom, to: queryTo, redirectTo } = queryParams

  const portfolioIds = useSelector(selectPortfolioIds)
  const currentPortfolioId = useSelector(selectCurrentPortfolioId)
  const isLoading = useSelector(selectInitialLoading)

  let initialChartType = defaultChartType
  if (chartTypePath) initialChartType = CHART_TYPE_PATHS[chartTypePath]

  const [chartType, setChartType] = useState(initialChartType)
  const [dateFrom, setDateFrom] = useState(queryFrom)
  const [dateTo, setDateTo] = useState(queryTo)

  const invalidRoute =
    isSignedIn &&
    ((routeId && portfolioIds && !portfolioIds.includes(routeId)) ||
      chartType === undefined)

  useEffect(() => {
    if (currentPortfolioId) return
    if (!routeId) return

    dispatch(setCurrentPortfolioId(routeId))
  }, [dispatch, routeId, currentPortfolioId])

  useEffect(() => {
    if (!isSignedIn) return
    if (!routeId) return
    if (invalidRoute) return
    if (routeId === currentPortfolioId) return

    dispatch(setCurrentPortfolioId(routeId))
  }, [dispatch, routeId, currentPortfolioId, isSignedIn, invalidRoute])

  useEffect(() => {
    callIntercom('show')
  }, [])

  useEffect(() => {
    if (allowedDeviceSize) callIntercom('show')
    else callIntercom('shutdown')
  }, [allowedDeviceSize])

  useEffect(() => {
    if (redirectTo && isSignedIn) history.push(redirectTo)
  }, [history, isSignedIn, redirectTo])

  useEffect(() => {
    if (chartTypePath) setChartType(CHART_TYPE_PATHS[chartTypePath])
  }, [chartTypePath])

  const handleChangeDate = useDeepCompareCallback(
    (fromDate, toDate) => {
      const chartPath = Object.keys(CHART_TYPE_PATHS).find(
        (key) => CHART_TYPE_PATHS[key] === chartType
      )
      const newPath = {
        pathname: `/portefoeljer/${currentPortfolioId}/${chartPath}`
      }

      if (fromDate === undefined && toDate === undefined) {
        setDateFrom(fromDate)
        setDateTo(toDate)
        history.push(newPath)
      } else if (fromDate !== undefined && toDate === undefined) {
        newPath.search = `?from=${fromDate}`
        setDateFrom(fromDate)
        setDateTo(toDate)
        history.push(newPath)
      } else if (fromDate !== undefined && toDate !== undefined) {
        newPath.search = `?from=${fromDate}&to=${toDate}`
        setDateFrom(fromDate)
        setDateTo(toDate)
        history.push(newPath)
      }
    },
    [history, chartType, currentPortfolioId]
  )

  const context = useMemo(
    () => ({
      dateFrom,
      dateTo,
      chartType,
      onChangeDate: handleChangeDate
    }),
    [dateFrom, dateTo, chartType, handleChangeDate]
  )

  let content
  if (isLoading || invalidRoute) content = <LoadingPage />
  else if (isSignedIn) content = <AuthPages />
  else content = <NotAuthPages />

  if (invalidRoute && currentPortfolioId)
    return (
      <Redirect
        to={`/portefoeljer/${currentPortfolioId}/${defaultChartTypePath}`}
      />
    )

  return <AppContext.Provider value={context}>{content}</AppContext.Provider>
}

export default PagesWrapper
