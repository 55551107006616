import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import PropTypes from 'prop-types'
import React from 'react'

import StartPage from './StartPage'
import WithdrawalPage from './WithdrawalPage'

const CashWithdrawal = ({ children }) => (
  <>
    <Text as="h4" align="center" className="my-3">
      Kontantudbetaling
    </Text>
    {children}
  </>
)

CashWithdrawal.propTypes = {
  children: PropTypes.node.isRequired
}

CashWithdrawal.StartPage = StartPage
CashWithdrawal.WithdrawalPage = WithdrawalPage

export default CashWithdrawal
