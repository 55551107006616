import { changeCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { updateUser } from '@nordinvestments/nord-storybook/dist/store/current/user'
import { useDispatch } from 'react-redux'

const useUpdateUserAndPortfolio = () => {
  const dispatch = useDispatch()

  const update = ({
    sustainabilityPreferenceLevel,
    sustainabilityPreferenceGoalAnswer,
    user: {
      initialSustainabilityPreferenceLevel,
      sustainabilityPreferencesOrder
    }
  }) => {
    dispatch(
      updateUser({
        attributes: {
          initialSustainabilityPreferenceLevel,
          sustainabilityPreferencesOrder
        }
      })
    )

    dispatch(
      changeCurrentPortfolio({
        sustainabilityPreferenceLevel,
        sustainabilityPreferenceGoalAnswer
      })
    )
  }

  return update
}

export default useUpdateUserAndPortfolio
