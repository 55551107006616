import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-bootstrap/Modal'

import styles from './Modal.module.scss'

const memberReferralAmount = getConfig('numbers.memberReferralAmount')

const ReferralTermsModal = ({ onHide, show }) => (
  <Modal
    className="text-dark"
    onHide={onHide}
    show={show}
    size="lg"
    backdropClassName={styles.backdropBackground}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title className="w-100 text-center" data-cy="modal-title">
        Betingelser
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Text as="p">
        Hvis din ven investerer minimum {numberToCurrency(50000)} i 1 måned med
        henvisning fra dig, vil du modtage en belønning på{' '}
        {numberToCurrency(memberReferralAmount)} fra NORD.investments på din
        investeringskonto forudsat, at følgende betingelser er opfyldt:
      </Text>
      <ul>
        <li>
          Din ven må ikke allerede være oprettet hos NORD.investments i dag
          eller have været kunde hos NORD.investments inden for de seneste 12
          måneder.
        </li>
        <li>
          Din ven skal kunne godkendes af NORD.investments som kunde og erklæres
          egnet til at investere samt have oprettet konto og depot hos Saxo
          Bank.
        </li>
      </ul>
      <Text as="p">
        Når betingelserne er opfyldt, vil du og din ven hver modtage{' '}
        {numberToCurrency(memberReferralAmount)}
      </Text>
      <Text as="p">
        De {numberToCurrency(memberReferralAmount)} vil blive indsat på din og
        din vens investeringskonto i kalendermåneden efter, at din ven har været
        investeret i 1 måned. Din ven kan altid trække sine penge ud, men I vil
        ikke få jeres {numberToCurrency(memberReferralAmount)}, hvis din ven
        ikke har haft de {numberToCurrency(50000)} investeret i 1 sammenhængende
        måned.
      </Text>
      <Text as="p">
        Du skal være opmærksom på, at belønningen kan påvirke din skattemæssige
        indkomst, og at du selv er ansvarlig for at indberette belønningen.
      </Text>
      <Text as="p">
        Vi forbeholder os retten til at tilbagekalde tilbuddet om belønning.
      </Text>
    </Modal.Body>
  </Modal>
)

ReferralTermsModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

export default ReferralTermsModal
