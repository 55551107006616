import isNaN from 'lodash/isNaN'
import { useRouteMatch } from 'react-router-dom'

const usePortfolioId = () => {
  const routeMatch = useRouteMatch('/portefoeljer/:id')

  let portfolioId
  if (routeMatch && routeMatch.params.id)
    portfolioId = parseInt(routeMatch.params.id, 10)

  if (isNaN(portfolioId)) return undefined

  return portfolioId
}

export default usePortfolioId
