import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import {
  changeCurrentPortfolio,
  selectCurrentPortfolio
} from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import AppContext from '../../../../context/AppContext'
import useIsPayoutAccount from '../../../../hooks/useIsPayoutAccount'

const useUpdatePortfolioReturnInDkk = (liveData, loadingLiveData) => {
  const dispatch = useDispatch()
  const isPayoutAccount = useIsPayoutAccount()
  const { dateTo, chartType } = useContext(AppContext)

  const { appState: portfolioAppState, returnInDkk } = useObjectSelector(
    selectCurrentPortfolio
  )
  const isInvested = portfolioAppState === 'invested'

  useEffect(() => {
    if (!isInvested) return
    if (isPayoutAccount) return
    if (chartType !== 'values') return
    if (loadingLiveData) return
    if (!liveData) return
    if (dateTo) return

    const { return: liveReturn } = liveData

    if (liveReturn === undefined) return
    if (liveReturn === returnInDkk) return

    dispatch(
      changeCurrentPortfolio({
        returnInDkk: liveReturn
      })
    )
  }, [
    chartType,
    dateTo,
    dispatch,
    isInvested,
    isPayoutAccount,
    liveData,
    loadingLiveData,
    returnInDkk
  ])
}

export default useUpdatePortfolioReturnInDkk
