import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import DescriptionList from '@nordinvestments/nord-storybook/dist/components/DescriptionList'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import React from 'react'

const AccountInformation = () => {
  const {
    saxoAccountNumber,
    registrationNumber,
    riskScoreTitle,
    esgTitle
  } = useObjectSelector(selectCurrentPortfolio)

  return (
    <>
      <Card className="mt-6 mx-6">
        <Card.Body>
          <DescriptionList>
            <DescriptionList.Item
              label="Reg.- og kontonummer"
              value={`${registrationNumber} - ${saxoAccountNumber}`}
            />
          </DescriptionList>
        </Card.Body>
      </Card>
      <Text as="p" align="center" className="mt-5">
        Dine midler vil blive investeret med risikoprofil {riskScoreTitle}
        {esgTitle && ` ${esgTitle}`}. Hvis du ønsker at ændre risikoprofilen så
        kontakt os.
      </Text>
    </>
  )
}

export default AccountInformation
