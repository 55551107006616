import styleConfiguration from '@nordinvestments/nord-storybook/dist/configuration/styleConfiguration'
import formatDate from '@nordinvestments/nord-storybook/dist/utilities/formatDate'
import {
  numberToCurrency,
  numberToPercent
} from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import classNames from 'classnames'
import max from 'lodash/max'
import min from 'lodash/min'
import PropTypes from 'prop-types'
import React from 'react'
import Card from 'react-bootstrap/Card'
import { withResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts'

import { VALID_CHART_TYPES } from '../../../../configuration/constants'

import styles from './index.module.scss'

const { blues } = styleConfiguration

const ChartContainer = ({ data, chartType, width, height }) => {
  const timeWeighted = chartType === 'timeWeighted'
  const historicalValues = chartType === 'values'
  const ticks = [data[0].timestamp, data[data.length - 1].timestamp]

  const dataValues = data.map((dataEntry) => dataEntry.value)
  const maxValue = max(dataValues)
  const minValue = min(dataValues)
  const dataValueDiff = maxValue - minValue
  const yAxisBuffer = historicalValues || timeWeighted ? dataValueDiff / 3.0 : 0
  const baseValue = minValue - yAxisBuffer

  return (
    <div className="flex-fill w-100">
      <AreaChart
        data={data}
        baseValue={baseValue}
        width={width}
        height={height}
        margin={{
          top: 20,
          right: 0,
          bottom: 0,
          left: 0
        }}
      >
        <defs>
          <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={blues[300]} stopOpacity={1} />
            <stop offset="95%" stopColor={blues[300]} stopOpacity={0.4} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="timestamp"
          ticks={ticks}
          tickFormatter={(timestamp) => formatDate(timestamp)}
          tickLine={false}
          axisLine={false}
          interval={0}
          mirror
          allowDataOverflow
        />
        <YAxis
          type="number"
          domain={[baseValue, 'dataMax']}
          datakey="value"
          hide
        />
        <Area
          dataKey="value"
          stroke="none"
          fill="url(#gradientFill)"
          fillOpacity={1}
        />
        <Tooltip
          cursor={false}
          content={({ payload }) => {
            if (!payload) return null
            if (!payload.length) return null
            if (!payload[0].payload) return null

            return (
              <Card className={styles.tooltip}>
                <Card.Body className={styles.tooltipBody}>
                  <Card.Title
                    className={classNames(styles.tooltipTitle, 'font-size-md')}
                  >
                    {timeWeighted
                      ? numberToPercent(payload[0].value, {
                          maximumFractionDigits: 2
                        })
                      : numberToCurrency(payload[0].value)}
                  </Card.Title>
                  <Card.Text className="font-size-sm">
                    {formatDate(payload[0].payload.timestamp)}
                  </Card.Text>
                </Card.Body>
              </Card>
            )
          }}
        />
      </AreaChart>
    </div>
  )
}

ChartContainer.defaultProps = {
  height: undefined,
  width: undefined
}

ChartContainer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ timestamp: PropTypes.number, value: PropTypes.number })
  ).isRequired,
  chartType: PropTypes.oneOf(VALID_CHART_TYPES).isRequired,
  height: PropTypes.number,
  width: PropTypes.number
}

export default withResizeDetector(ChartContainer)
