import { useRouteMatch } from 'react-router-dom'

const validChartTypes = ['historisk', 'tidsvaegtet', 'afkast']

const useChartType = () => {
  const routeMatch = useRouteMatch('/portefoeljer/:id/:chartType')

  let chartType
  if (routeMatch && routeMatch.params.chartType)
    chartType = routeMatch.params.chartType

  if (validChartTypes.includes(chartType)) return chartType

  return undefined
}

export default useChartType
