import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'

const useIsPayoutAccount = () => {
  const { depotType } = useObjectSelector(selectCurrentPortfolio)

  const isPayoutAccount = depotType === 'pensions_udbetaling'

  return isPayoutAccount
}

export default useIsPayoutAccount
