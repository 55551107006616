import isArray from 'lodash/isArray'

const formatDataForChart = (data, chartType) => {
  if (!data || !chartType || !isArray(data))
    return { formatting: true, chartData: undefined }

  const timeWeighted = chartType === 'timeWeighted'
  const formattedData = data
    .map((chartDataEntry) => {
      const formattedValue = {
        timestamp: Date.parse(chartDataEntry[0]) / 1000,
        value: timeWeighted ? chartDataEntry[1] - 1 : chartDataEntry[1]
      }

      return formattedValue
    })
    .reverse()

  return {
    formatting: false,
    formattedData
  }
}

export default formatDataForChart
