import ApplicationWrapper from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper'
import CookieConsent from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/CookieConsent'
import EnvironmentBar from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/EnvironmentBar'
import ErrorBoundary from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/ErrorBoundary'
import Intercom from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/Intercom'
import PageLayout from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/PageLayout'
import ReduxProvider from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/ReduxProvider'
import Router from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/Router'
import ToastNotifications from '@nordinvestments/nord-storybook/dist/components/ApplicationWrapper/plugins/ToastNotifications'
import React from 'react'

import PagesWrapper from './components/PagesWrapper'

const App = () => (
  <ApplicationWrapper
    plugins={[
      Router,
      ErrorBoundary,
      ReduxProvider,
      Intercom,
      ToastNotifications,
      CookieConsent,
      EnvironmentBar,
      PageLayout
    ]}
    pluginsProps={{
      PageLayout: {
        className: 'p-0',
        fluid: true
      },
      ReduxProvider: {
        loadPortfolios: 'ready_to_onboard'
      }
    }}
  >
    <PagesWrapper />
  </ApplicationWrapper>
)

export default App
