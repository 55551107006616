import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import PropTypes from 'prop-types'
import React from 'react'

const MessageCard = ({ title, message, children, ...otherProps }) => (
  <Card {...otherProps}>
    <Card.Body>
      <Card.Title as="h2" align="center" className="mb-3">
        {title}
      </Card.Title>
      <Text as="p" align="center">
        {message}
      </Text>
      {children}
    </Card.Body>
  </Card>
)

MessageCard.defaultProps = {
  children: undefined,
  message: undefined
}

MessageCard.propTypes = {
  children: PropTypes.node,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string.isRequired
}

export default MessageCard
