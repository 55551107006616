import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import LogOutButton from '@nordinvestments/nord-storybook/dist/components/LogOutButton'
import NavMenu from '@nordinvestments/nord-storybook/dist/components/NavMenu'
import useBreakpointHandler from '@nordinvestments/nord-storybook/dist/hooks/useBreakpointHandler'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { selectPortfolios } from '@nordinvestments/nord-storybook/dist/store/current/portfolios'
import { selectCurrentUser } from '@nordinvestments/nord-storybook/dist/store/current/user'
import classNames from 'classnames'
import React, { useContext } from 'react'
import ChevronDown from 'react-feather/dist/icons/chevron-down'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import AppContext from '../../context/AppContext'
import useIsPayoutAccount from '../../hooks/useIsPayoutAccount'

import ExpandableMobileMenu from './ExpandableMobileMenu'
import styles from './index.module.scss'
import MyAccountMenuItem from './MyAccountMenuItem'

const chartTypeLabels = {
  values: 'Overblik',
  timeWeighted: 'Afkast'
}

const chartTypePaths = {
  values: 'historisk',
  timeWeighted: 'tidsvaegtet'
}

const useNavigationItems = ({
  onToggleMobileNav,
  isInvested,
  isCreatedInSaxo
}) => {
  const { dateFrom, dateTo } = useContext(AppContext)
  const largeAndUp = useBreakpointHandler('lg')
  const { appState: userAppState } = useSelector(selectCurrentUser)
  const portfoliosData = useSelector(selectPortfolios)
  const { id: portfolioId } = useObjectSelector(selectCurrentPortfolio)
  const isPensionPayoutAccount = useIsPayoutAccount()

  let dateParam
  if (dateFrom) dateParam = `?from=${dateFrom}`
  if (dateFrom && dateTo) dateParam = `?from=${dateFrom}&to=${dateTo}`

  const showChangePortfolioOption = Object.keys(portfoliosData).length > 1

  const navigationItems = {
    left: [],
    right: [],
    footer: []
  }

  let mainNavItems = []
  if (isInvested) {
    mainNavItems = Object.entries(chartTypeLabels).map(([type, label]) => (
      <NavMenu.Item key={type}>
        <NavMenu.Link
          className={largeAndUp ? undefined : styles.mobileChartTypeItem}
          as={NavLink}
          activeClassName="text-white"
          to={{
            pathname: `/portefoeljer/${portfolioId}/${chartTypePaths[type]}`,
            search: dateParam
          }}
          animated
        >
          {label}
        </NavMenu.Link>
      </NavMenu.Item>
    ))
  }

  if (isCreatedInSaxo && !isPensionPayoutAccount)
    mainNavItems.push(
      <NavMenu.Item>
        <NavMenu.Link
          className={largeAndUp ? undefined : styles.mobileChartTypeItem}
          as={NavLink}
          to={`/portefoeljer/${portfolioId}/ny_indbetaling`}
          activeClassName="text-white"
          animated
        >
          Ny indbetaling
        </NavMenu.Link>
      </NavMenu.Item>
    )

  if (largeAndUp) navigationItems.left = mainNavItems
  else navigationItems.footer = mainNavItems

  if (largeAndUp && portfolioId && userAppState !== 'user_created')
    navigationItems.left.push(
      <NavMenu.Item>
        <NavMenu.Link
          as={NavLink}
          to={`/portefoeljer/${portfolioId}`}
          activeClassName="text-white"
          isActive={(match, location) => {
            if (!match) return false
            if (
              Object.values(chartTypePaths).includes(
                location.pathname.split('/').slice(-1)[0]
              )
            )
              return false

            if (
              ['information', 'ny_indbetaling'].includes(
                location.pathname.split('/').slice(-1)[0]
              )
            )
              return false

            return true
          }}
          animated
        >
          Mere
        </NavMenu.Link>
      </NavMenu.Item>
    )

  navigationItems.right = [
    <NavMenu.Item
      className={classNames({
        'd-none': !showChangePortfolioOption
      })}
    >
      <NavMenu.Link
        as={NavLink}
        to="/portefoeljer"
        activeClassName="text-white"
        onClick={() => onToggleMobileNav(false)}
        exact
        animated
      >
        Skift portefølje
        <Icon icon={ChevronDown} className="d-none d-lg-inline-block" />
      </NavMenu.Link>
    </NavMenu.Item>,
    <ExpandableMobileMenu />,
    <MyAccountMenuItem />,
    <NavMenu.Item>
      <LogOutButton as={NavMenu.Link} label="Log ud" animated />
    </NavMenu.Item>
  ]

  return navigationItems
}

export default useNavigationItems
