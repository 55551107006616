export const CHART_TYPE_ENDPOINTS = {
  values: 'historical_values',
  returns: 'historical_returns',
  timeWeighted: 'time_weighted_returns'
}

export const CHART_TYPE_PATHS = {
  historisk: 'values',
  afkast: 'returns',
  tidsvaegtet: 'timeWeighted'
}

export const ASSETS_TYPE = {
  stocks: 'aktier',
  properties: 'ejendomme',
  corporateBonds: 'virksomhedsobligationer',
  governmentBonds: 'statsobligationer',
  cash: 'kontanter'
}

export const VALID_CHART_TYPES = Object.keys(CHART_TYPE_ENDPOINTS)
