import DocumentsForm from '@nordinvestments/nord-storybook/dist/components/DocumentsForm'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nordinvestments/nord-storybook/dist/store/current/portfolioId'
import { selectCurrentUser } from '@nordinvestments/nord-storybook/dist/store/current/user'
import React, { useContext } from 'react'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
import X from 'react-feather/dist/icons/x'
import { useSelector } from 'react-redux'
import { Switch, Route, useRouteMatch, Link, Redirect } from 'react-router-dom'

import CloseNavItem from '../../../components/CloseNavItem'
import ModalPage from '../../../components/ModalPage'
import { CHART_TYPE_PATHS } from '../../../configuration/constants'
import AppContext from '../../../context/AppContext'

import AccountCreations from './AccountCreations'
import CashDeposit from './CashDeposit'
import CashWithdrawal from './CashWithdrawal'
import ChangePassword from './ChangePassword'
import Information from './Information'
import Invoices from './Invoices'
import Referrals from './Referrals'

const validProfileSubpages = {
  oprettelser: () => true,
  dokumenter: () => true,
  adgangskode: () => true,
  fakturaer: () => true,
  kontant_udbetaling: () => true,
  kontant_indbetaling: ({ accountType }) => accountType === 'private_account',
  henvisning: () => true
}

const profileActiveKeys = {
  null: 'accountInformation',
  dokumenter: 'documents',
  fakturaer: 'invoices',
  // eslint-disable-next-line camelcase
  kontant_indbetaling: 'cashDeposit',
  oprettelser: 'accountsCreation',
  adgangskode: 'changePassword',
  henvisning: 'referrals'
}

const ProfilePage = () => {
  const { chartType } = useContext(AppContext)

  const { email, accountType, appState: userAppState } = useSelector(
    selectCurrentUser
  )
  const { appState, id: currentPortfolioId } = useObjectSelector(
    selectCurrentPortfolio
  )

  const isUserCreated = userAppState === 'user_created'
  const isCompany = accountType === 'company_account'
  const { url } = useRouteMatch()
  const routeMatch = useRouteMatch('/konto/:subpage')
  const profileSubpage = routeMatch && routeMatch.params.subpage

  const chartParam = Object.keys(CHART_TYPE_PATHS).find(
    (key) => CHART_TYPE_PATHS[key] === chartType
  )

  const isInvested = appState === 'invested' || appState === 'deposit_recieved'

  let defaultBackParam
  if (isInvested) defaultBackParam = chartParam
  else defaultBackParam = 'information'

  const backUrl = currentPortfolioId
    ? `/portefoeljer/${currentPortfolioId}/${defaultBackParam}`
    : '/'

  if (profileSubpage && !validProfileSubpages[profileSubpage]({ accountType }))
    return <Redirect to={url} />

  const activeSection = profileActiveKeys[profileSubpage]

  return (
    <Tab.Container defaultActiveKey={activeSection}>
      <ModalPage
        title="Min konto"
        backUrl={backUrl}
        modalHeaderClassName="p-0"
        header={
          <>
            <Nav
              variant="tabs"
              className="w-100 d-none d-lg-flex flex-column flex-lg-row"
              fill
            >
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={url}
                  eventKey="accountInformation"
                  className="link-dark"
                >
                  Mine informationer
                </Nav.Link>
              </Nav.Item>
              {!isCompany && (
                <Nav.Item className="d-none d-lg-block">
                  <Nav.Link
                    as={Link}
                    to={`${url}/kontant_indbetaling/opret`}
                    eventKey="cashDeposit"
                    className="link-dark"
                  >
                    Kontant indbetaling
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item className="d-none d-lg-block">
                <Nav.Link
                  as={Link}
                  to={`${url}/dokumenter`}
                  eventKey="documents"
                  className="link-dark"
                >
                  Dokumenter
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="d-none d-lg-block">
                <Nav.Link
                  as={Link}
                  to={`${url}/fakturaer`}
                  eventKey="invoices"
                  className="link-dark"
                >
                  Fakturaer
                </Nav.Link>
              </Nav.Item>
              {(!isCompany || isUserCreated) && (
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to={`${url}/oprettelser`}
                    eventKey="accountsCreation"
                    className="link-dark"
                  >
                    Depotoprettelser
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={`${url}/henvisning`}
                  eventKey="referrals"
                  className="link-dark"
                >
                  Henvisning
                </Nav.Link>
              </Nav.Item>
              <CloseNavItem backUrl={backUrl} />
            </Nav>
            <div className="d-block ml-auto p-2 d-lg-none">
              <Link to={backUrl} className="link-dark">
                <Icon icon={X} size={2} />
              </Link>
            </div>
          </>
        }
      >
        <Tab.Content>
          <Switch>
            <Tab.Pane
              as={Route}
              path={url}
              eventKey="portfolioInformation"
              exact
            >
              <Information />
            </Tab.Pane>
            <Tab.Pane
              as={Route}
              path={`${url}/henvisning`}
              eventKey="referrals"
              exact
            >
              <Referrals />
            </Tab.Pane>
            <Tab.Pane
              as={Route}
              path={`${url}/dokumenter`}
              eventKey="documents"
              exact
            >
              <DocumentsForm email={email} />
            </Tab.Pane>
            <Tab.Pane
              as={Route}
              path={`${url}/fakturaer`}
              eventKey="invoices"
              exact
            >
              <Invoices />
            </Tab.Pane>
            <Tab.Pane
              as={Route}
              path={`${url}/kontant_udbetaling/verificer`}
              exact
            >
              <CashWithdrawal>
                <CashWithdrawal.StartPage />
              </CashWithdrawal>
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/kontant_udbetaling/opret`} exact>
              <CashWithdrawal>
                <CashWithdrawal.WithdrawalPage />
              </CashWithdrawal>
            </Tab.Pane>
            {(!isCompany || isUserCreated) && (
              <Tab.Pane
                as={Route}
                path={`${url}/oprettelser`}
                eventKey="accountsCreation"
                exact
              >
                <AccountCreations />
              </Tab.Pane>
            )}
            <Tab.Pane
              as={Route}
              path={`${url}/adgangskode`}
              eventKey="changePassword"
              exact
            >
              <ChangePassword />
            </Tab.Pane>
            {!isCompany && (
              <Tab.Pane
                as={Route}
                path={`${url}/kontant_indbetaling/opret`}
                eventKey="cashDeposit"
                exact
              >
                <CashDeposit>
                  <CashDeposit.StartPage />
                </CashDeposit>
              </Tab.Pane>
            )}
            {!isCompany && (
              <Tab.Pane
                as={Route}
                path={`${url}/kontant_indbetaling/:paymentId`}
                eventKey="cashDeposit"
                exact
              >
                <CashDeposit>
                  <CashDeposit.ResultPage />
                </CashDeposit>
              </Tab.Pane>
            )}
          </Switch>
        </Tab.Content>
      </ModalPage>
    </Tab.Container>
  )
}

export default ProfilePage
