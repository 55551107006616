import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { numberToPercent } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import PropTypes from 'prop-types'
import React from 'react'

const TimeWeightedReturns = ({ liveData, isLoading, ...otherProps }) => (
  <div {...otherProps}>
    <Text as="h6" align="center">
      Afkast
    </Text>
    <Text as="h1" align="center" variant="white" className="mb-0">
      {isLoading || liveData === undefined || liveData.return ? (
        <Loader />
      ) : (
        numberToPercent(liveData.value - 1, { maximumFractionDigits: 2 })
      )}
    </Text>
  </div>
)

TimeWeightedReturns.propTypes = {
  liveData: PropTypes.shape({
    value: PropTypes.number.isRequired,
    return: PropTypes.number
  }),
  isLoading: PropTypes.bool.isRequired
}

TimeWeightedReturns.defaultProps = {
  liveData: undefined
}

export default TimeWeightedReturns
