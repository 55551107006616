import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import { selectPortfolioById } from '@nordinvestments/nord-storybook/dist/store/current/portfolios'

const warningText =
  'Vær opmærksom på, at pensionskonti har en årlig grænse for indbetalinger, og den er forskellig for hver type af pension. Sørg for, at du kan indsætte penge på din pensionskonto uden at overskride den årlige grænse. Denne handling kan ikke fortrydes.'

const usePensionWarning = (id) => {
  const { pensionDepot: pension } = useObjectSelector(selectPortfolioById(id))

  if (!pension) return undefined

  return warningText
}

export default usePensionWarning
