import PropTypes from 'prop-types'
import React, { createContext, useContext, useMemo, useState } from 'react'

export const Context = createContext()

export const useSustainabilityContext = () => useContext(Context)

const Provider = ({ children }) => {
  const [questionsVersion, setQuestionsVersion] = useState()
  const [answerData, setAnswerData] = useState()
  const [preferencesOrder, setPreferencesOrder] = useState()

  const cleanupContext = () => {
    setQuestionsVersion()
    setAnswerData()
    setPreferencesOrder()
  }

  const context = useMemo(
    () => ({
      questionsVersion,
      setQuestionsVersion,
      answerData,
      setAnswerData,
      preferencesOrder,
      setPreferencesOrder,
      cleanupContext
    }),
    [answerData, preferencesOrder, questionsVersion]
  )

  return <Context.Provider value={context}>{children}</Context.Provider>
}

Provider.propTypes = {
  children: PropTypes.node.isRequired
}

export default Provider
