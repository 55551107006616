import NavMenu from '@nordinvestments/nord-storybook/dist/components/NavMenu'
import React from 'react'
import { NavLink } from 'react-router-dom'

const MyAccountMenuItem = () => (
  <NavMenu.Item className="d-none d-lg-block">
    <NavMenu.Link
      as={NavLink}
      to="/konto"
      activeClassName="text-white"
      animated
    >
      Min konto
    </NavMenu.Link>
  </NavMenu.Item>
)

export default MyAccountMenuItem
