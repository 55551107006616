import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import classNames from 'classnames'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { ASSETS_TYPE } from '../../../../configuration/constants'

import { useAllocationContext } from './AllocationContext'
import Dot from './Dot'

const List = () => {
  const { allocationData, chartData } = useAllocationContext()

  return (
    <Col xs={12} lg={6} className="text-left my-auto">
      <ul className="list-unstyled">
        {allocationData.map(
          (
            { category, label, danishName, humanizedPercentage, value },
            index
          ) => {
            const isLast = index + 1 === allocationData.length

            return (
              <Row
                as="li"
                className={classNames('py-2', {
                  'border-bottom': !isLast
                })}
                key={label}
              >
                <Col>
                  <div className="d-flex flex-row align-items-center">
                    {chartData.map(({ name, color }) => {
                      if (category !== name) return null

                      return <Dot fill={color} width="10px" height="10px" />
                    })}
                    <Text
                      as="p"
                      align="left"
                      transform="uppercase"
                      className="pl-2 mb-0"
                    >
                      {startCase(danishName)}
                    </Text>
                  </div>
                  <Text
                    as="div"
                    align="left"
                    transform="capitalize"
                    variant="secondary"
                  >
                    {ASSETS_TYPE[camelCase(category)]}
                  </Text>
                </Col>
                <Col>
                  <Text as="div" align="right">
                    {humanizedPercentage}
                  </Text>
                  <Text
                    as="div"
                    align="right"
                    transform="uppercase"
                    variant="secondary"
                  >
                    {numberToCurrency(value)}
                  </Text>
                </Col>
              </Row>
            )
          }
        )}
      </ul>
    </Col>
  )
}

export default List
