import { object, string, ref } from 'yup'

const schema = {
  currentPassword: string().required(),
  password: string().required().min(6).max(128),
  passwordConfirmation: string()
    .required()
    .equals([ref('password')], 'Adgangskoder stemmer ikke overens')
}

const validationSchema = object().shape(schema)

export default validationSchema
