import BooleanInput from '@nordinvestments/nord-storybook/dist/components/BooleanInput'
import Form from '@nordinvestments/nord-storybook/dist/components/Form'
import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import Transition from '@nordinvestments/nord-storybook/dist/components/Transition'
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import useApi, {
  useApiRequest
} from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import useObjectSelector from '@nordinvestments/nord-storybook/dist/hooks/useObjectSelector'
import {
  selectCurrentUser,
  updateUser
} from '@nordinvestments/nord-storybook/dist/store/current/user'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import device from 'current-device'
import { detect } from 'detect-browser'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import CenteredTextSection from '../../../../components/CenteredTextSection'

import ConsentTermsModal from './ConsentTermsModal'
import ReferralRow from './ReferralRow'
import ReferralTermsModal from './ReferralTermsModal'

const memberReferralAmount = getConfig('numbers.memberReferralAmount')

const referralMainMessage = `Opret dig og invester hos NORD.investments, så får vi hver ${numberToCurrency(
  memberReferralAmount
)} sat ind på vores investeringskonto. Følg linket for at læse betingelserne. Kontakt mig eller NORD.investments, hvis du har nogle spørgsmål.`

const Referrals = () => {
  const [showConsentTermsModal, setShowConsentTermsModal] = useState(false)

  const handleShowConsentTermsModal = () => setShowConsentTermsModal(true)
  const handleHideConsentTermsModal = () => setShowConsentTermsModal(false)

  const [showReferralTermsModal, setShowReferralTermsModal] = useState(false)

  const handleShowReferralTermsModal = () => setShowReferralTermsModal(true)
  const handleHideReferralTermsModal = () => setShowReferralTermsModal(false)

  const {
    investmentState,
    email,
    firstName,
    referralEmailConsent,
    referralLink
  } = useObjectSelector(selectCurrentUser)

  const userIsInvested = investmentState === 'invested'

  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const callGetReferrals = useApi('/users/referrals', {
    withCredentials: true
  })

  const { data: referralsData, loading } = useApiRequest(callGetReferrals, {
    autoCall: true
  })

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink)
    addToast('Linket er kopieret til din udklipsholder.', {
      appearance: 'info',
      autoDismiss: true
    })
  }

  const callUpdateUser = useApi('/users/referral_email_consents', {
    withCredentials: true,
    method: 'PATCH'
  })

  const { request: updateUserData } = useApiRequest(callUpdateUser)

  const handleReferralConsentChange = async (newReferralEmailConsent) => {
    const { data, success } = await updateUserData({
      user: { referralEmailConsent: newReferralEmailConsent }
    })

    const {
      referralEmailConsent: updatedReferralEmailConsent,
      referralLink: updatedReferralLink
    } = data || {}

    if (success) {
      dispatch(
        updateUser({
          attributes: {
            referralEmailConsent: updatedReferralEmailConsent,
            referralLink: updatedReferralLink
          }
        })
      )
    }
  }

  const browser = detect()
  const isIE = browser.name === 'ie'
  const { version: browserVersion } = browser
  const formattedBrowserVersion = parseInt(browserVersion.substring(0, 2), 10)

  const referralSubject = `${firstName} har anbefalet NORD.investments`
  const referralMessage = encodeURI(
    `Hej!\n${referralMainMessage}\n\nLink: ${referralLink}\n\nDe bedste hilsner\n${firstName}`
  )

  let referralEmail
  if (
    device.ios() &&
    (browser.name !== 'safari' || formattedBrowserVersion === 14)
  ) {
    referralEmail = encodeURI(referralLink)
  } else {
    referralEmail = referralMessage
  }

  const handleCopy = (event) => {
    event.clipboardData.setData('text/plain', referralLink)
    event.preventDefault()
    addToast('Linket er kopieret til din udklipsholder.', {
      appearance: 'info',
      autoDismiss: true
    })
  }

  return (
    <>
      <Text as="h4" align="center" className="my-3">
        Henvis en ven og få {numberToCurrency(memberReferralAmount)} hver
      </Text>
      <CenteredTextSection>
        <ReferralTermsModal
          show={showReferralTermsModal}
          onHide={handleHideReferralTermsModal}
        />
        <Text as="p">
          {userIsInvested
            ? `Når du deler nedenstående link, får din ven også oplyst din e-mail
              ${email}, så din ven nemt kan kontakte dig med spørgsmål og høre,
              hvorfor du anbefaler NORD.investments.`
            : 'Du kan anbefale og invitere NORD til en ven så snart, at du er blevet investeret.'}{' '}
          Når du anbefaler NORD.investments, indvilliger du i at svare på
          spørgsmål fra din ven. Når din ven har investeret{' '}
          {numberToCurrency(50000)} i minimum en måned, belønner vi både dig og
          din ven med hver {numberToCurrency(memberReferralAmount)}{' '}
          <Button
            variant="link"
            className="btn-anchor text-base font-size-md p-0"
            onClick={handleShowReferralTermsModal}
          >
            Læs alle betingelser for belønningen her.
          </Button>
        </Text>
        {userIsInvested && (
          <>
            <BooleanInput
              name="referralEmailConsent"
              type="checkbox"
              label="Jeg giver hermed samtykke til, at NORD.investments må dele mit navn og e-mail til de venner jeg kontakter."
              checked={referralEmailConsent}
              onCheckedChange={handleReferralConsentChange}
            />
            <Button
              variant="link"
              className="text-base font-size-md p-0 btn-anchor"
              onClick={handleShowConsentTermsModal}
            >
              Læs mere om samtykket her.
            </Button>
            <ConsentTermsModal
              show={showConsentTermsModal}
              onHide={handleHideConsentTermsModal}
            />
          </>
        )}
      </CenteredTextSection>
      <Transition.Expand
        in={referralLink}
        // appear={false} // Fix this in storybook.
      >
        <Row className="py-3">
          <Col xs={12} lg={{ span: 8, offset: 2 }}>
            <Form>
              <Row>
                <Col xs={12}>
                  <InputGroup>
                    <Form.Control
                      name="referralLink"
                      onCopy={handleCopy}
                      value={referralLink}
                      className="bg-light"
                    />
                    <InputGroup.Append>
                      <DropdownButton
                        variant="primary"
                        title="Del link"
                        align="end"
                      >
                        {!isIE && (
                          <Dropdown.Item onClick={handleCopyLink}>
                            Kopier link
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          href={`mailto:?subject=${referralSubject}&body=${referralEmail}`}
                        >
                          Mail
                        </Dropdown.Item>
                        {device.mobile() && (
                          <Dropdown.Item href={`sms:?&body=${referralMessage}`}>
                            SMS
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                            referralLink
                          )}&quote=${encodeURI(referralMainMessage)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Facebook
                        </Dropdown.Item>
                      </DropdownButton>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Transition.Expand>
      <Text as="h4" align="center" className="my-4">
        Dine belønninger
      </Text>
      <Row>
        <Col xs={12} lg={{ span: 8, offset: 2 }}>
          {loading ? (
            <Loader className="text-center font-size-xl" />
          ) : (
            <ul className="list-unstyled px-2">
              {isEmpty(referralsData) ? (
                <Text as="p" align="center">
                  Der er ingen belønninger at vise. Henvis en ven og få en
                  belønning på {numberToCurrency(memberReferralAmount)}
                </Text>
              ) : (
                referralsData.map((referralData) => (
                  <ReferralRow {...referralData} />
                ))
              )}
            </ul>
          )}
        </Col>
      </Row>
    </>
  )
}

export default Referrals
