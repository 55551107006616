import CurrencyInput from '@nordinvestments/nord-storybook/dist/components/CurrencyInput'
import Form from '@nordinvestments/nord-storybook/dist/components/Form'
import Select from '@nordinvestments/nord-storybook/dist/components/Select'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useApi from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import useQuery from '@nordinvestments/nord-storybook/dist/hooks/useQuery'
import { selectPortfolios } from '@nordinvestments/nord-storybook/dist/store/current/portfolios'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { object, number } from 'yup'

import usePageLeaveWarning from '../../../../hooks/usePageLeaveWarning'

import usePensionWarning from './usePensionWarning'

const labelDescriptions = {
  portfolioId: 'Vælg NORD konto',
  amount: 'Beløb',
  transactionText: 'Tekst til din egen bank'
}

const validationSchema = object().shape({
  amount: number().min(1).required()
})

const StartPage = () => {
  const portfoliosData = useSelector(selectPortfolios)
  const portfolioIdQueryParam = useQuery('portfolioId')

  usePageLeaveWarning()

  const filteredPortfoliosData = portfoliosData.filter(
    ({ createdInSaxoAt, depotType }) =>
      !!createdInSaxoAt && depotType !== 'pensions_udbetaling'
  )
  const hasOnlyOnePortfolio = filteredPortfoliosData.length === 1
  const portfoliosOptions = filteredPortfoliosData.map(
    ({ id, title, registrationNumber, saxoAccountNumber, totalValue }) => {
      const totalValueFormatted = numberToCurrency(totalValue)

      return {
        value: id,
        label: `${title}: ${registrationNumber} - ${saxoAccountNumber} (${totalValueFormatted})`
      }
    }
  )

  let defaultPortfolioId
  if (hasOnlyOnePortfolio) defaultPortfolioId = portfoliosOptions[0].value
  if (portfolioIdQueryParam)
    defaultPortfolioId = parseInt(portfolioIdQueryParam, 10)

  const [portfolioId, setPortfolioId] = useState(defaultPortfolioId)

  const pensionWarning = usePensionWarning(portfolioId)

  const depositCash = useApi(`/users/payments`, {
    method: 'POST',
    withCredentials: true
  })

  const handleDeposit = async ({ amount, transactionText }) => {
    const { data, success } = await depositCash({
      payment: { amount, transactionText, portfolioId }
    })

    if (!success) return

    const { redirectUrl } = data

    window.location.replace(redirectUrl)
  }

  return (
    <>
      <Text as="p" align="center">
        Du kan nemt indbetale penge til din portefølje, og vi vil investere dem
        med det samme for dig, hvis du har{' '}
        {portfolioId ? (
          <Link
            to={`/portefoeljer/${portfolioId}/forhandsgodkendelse`}
            title="Forhåndsgodkendelse"
          >
            forhåndsgodkendt
          </Link>
        ) : (
          'forhåndsgodkendt'
        )}{' '}
        dette. Vær opmærksom på, at det kan tage op til 2 bankdage før pengene
        er på din konto.
      </Text>
      {pensionWarning && <Alert variant="warning">{pensionWarning}</Alert>}
      <Alert variant="warning">
        Vi understøtter desværre ikke nemme overførsler fra Danske Bank på
        nuværende tidspunkt, men det kommer snarest.
      </Alert>
      <Form
        labelDescriptions={labelDescriptions}
        validationSchema={validationSchema}
        onSubmit={handleDeposit}
        initialValues={{
          portfolioId,
          transactionText: 'NORD.investments indbetaling'
        }}
        className="text-left"
        enableReinitialize
      >
        <Form.FieldGroup name="portfolioId">
          <Form.Field
            inputComponent={Select}
            options={portfoliosOptions}
            onValueChange={setPortfolioId}
            isSearchable={false}
            placeholder="Vælg konto..."
          />
        </Form.FieldGroup>
        <Form.FieldGroup name="amount">
          <Form.Field as={CurrencyInput} />
        </Form.FieldGroup>
        <Form.FieldGroup name="transactionText">
          <Form.Field />
        </Form.FieldGroup>
        <div className="d-flex justify-content-center my-4">
          <Form.SubmitButton variant="primary">Indbetal</Form.SubmitButton>
        </div>
      </Form>
    </>
  )
}

export default StartPage
