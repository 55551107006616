import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'

const FormPageWrapper = ({ children }) => (
  <Col xs={12} md={10} lg={8} xl={6} className="px-2 px-sm-3">
    <Card>
      <Card.Body>{children}</Card.Body>
    </Card>
  </Col>
)

FormPageWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default FormPageWrapper
