import React, { useState } from 'react'

import CustomDateRangePopover from './CustomDateRangePopover'
import DateButton from './DateButton'

const CustomDateRangeButton = ({ ...props }) => {
  const [showPopover, setShowPopover] = useState(false)
  const [target, setTarget] = useState()

  const handleShowPopover = (event) => {
    setShowPopover(true)
    setTarget(event.target)
  }

  const handleClosePopover = () => setShowPopover(false)

  return (
    <>
      <DateButton onClick={handleShowPopover} {...props}>
        Andet
      </DateButton>
      <CustomDateRangePopover
        target={target}
        show={showPopover}
        onClose={handleClosePopover}
      />
    </>
  )
}

export default CustomDateRangeButton
